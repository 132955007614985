import { Box, Typography } from '@mui/material';
import './Contribute.css';
import ContributionFormComponent from './Components/ContributionFormComponent';
import ReactGA from 'react-ga4'
import { useEffect } from 'react';

export default function Contribute() {
    useEffect(() => {
        ReactGA.event(
            {
                category: 'user-events',
                action: 'main-page-contribute'
            }
        )
    }, []);
    return (
        <Box component="section" sx={{
            p: {
                xs: 3,
                sm: 8
            },
            paddingBottom: {
                xs: 2,
                sm: 2
            },
            overflow: 'clip',
            maxHeight:
            {
                md: 900,
                xs: 1200
            }
        }} className='box-container'>
            <Typography
                variant='h2'
                style={{ fontWeight: 700 }}
                letterSpacing={1.5}
                align='left'
                sx={{ paddingBottom: 2 }}>
                Become part of our journey
            </Typography>
            <Typography
                variant='body1'
                color={'text.secondary'}
                align='left'>
                Our project aims to collect the finest learning materials for all openings. <br />
                Assessing material quality on the internet can be challenging, which is why we need YOUR help!  <br />
                If you’re aware of any quality learning materials that we haven’t included, please share them with us using the form below. <br />
            </Typography>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
            }}>
                <Box sx={{
                    marginTop: 6,
                    marginBottom: 2,
                    marginLeft: 3,
                    marginRight: 3,
                }}
                >
                    <ContributionFormComponent />
                </Box>
            </Box>
            <Box height={500}>
            </Box>
        </Box>
    );
}