import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

type EmptyInputDialogProps = {
    open: boolean;
    onClose: () => void;
}

export default function EmptyInputDialog(props: EmptyInputDialogProps) {
    return (
        <div>
            <Dialog
                open={props.open}
                onClose={props.onClose}
                aria-labelledby="alert-dialog-title"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Please enter your profile name or profile URL in the search bar"}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={props.onClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
