export enum MaterialModelType {
    Video = 'Video',
    Book = 'Book',
    NotableGame = 'Notable Game',
    Puzzle = 'Puzzle',
    Study = 'Study',
    Course = 'Course',
    AnythingElse = 'Anything Else'
  }

export class MaterialModel {
    title?: string;
    url: string;
    type: MaterialModelType;
    thumbnailURL?: string;
    origin?: string;
    timestamp?: string;

    constructor(url: string, type: MaterialModelType, title?: string, thumbnailURL?: string, origin?: string, timestamp?: string) {
        this.url = url;
        this.title = title;
        this.thumbnailURL = thumbnailURL;
        this.type = type;
        this.origin = origin;
        this.timestamp = timestamp;
    }
}

export class OpeningModel {
    eco?: string;
    name: string;
    variation?: string;

    constructor(name: string) {
        this.name = name
    }
}
