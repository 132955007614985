import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

type MaterialTypeDropDownProps = {
  materialSelected: (material: string) => void;
}

export default function MaterialTypeDropDown(props: MaterialTypeDropDownProps) {
  const [itemType, setItemType] = React.useState('');

  const handleChange = (event: SelectChangeEvent) => {
    setItemType(event.target.value as string);
    props.materialSelected(event.target.value as string);
  };

  return (
    <FormControl sx={{ minWidth: 200, maxWidth: 240, marginBottom: 2 }} >
      <InputLabel>Contribution Type</InputLabel>
      <Select
        value={itemType}
        label="Contribution Type"
        onChange={handleChange}
      >
        <MenuItem value={"Video"}>Video</MenuItem>
        <MenuItem value={"Book"}>Book</MenuItem>
        <MenuItem value={"Famous game"}>Famous game</MenuItem>
        <MenuItem value={"Puzzle"}>Puzzle</MenuItem>
        <MenuItem value={"Study"}>Study</MenuItem>
        <MenuItem value={"Course"}>Course</MenuItem>
        <MenuItem value={"Else"}>Else</MenuItem>
      </Select>
    </FormControl>
  );
}
