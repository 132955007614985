import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

type AlertDialogProps = {
    open: boolean;
    lichessSelected: () => void;
    chessComSelected: () => void;
}

export default function AlertDialog(props: AlertDialogProps) {

    const handleLichess = () => {
        props.lichessSelected();
    }

    const handleChessCom = () => {
        props.chessComSelected();
    }

    return (
        <Dialog
            open={props.open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Multiple profiles found"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    We found both Lichess and Chess.com profiles associated with this alias. Please choose which one you want to analyse.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleLichess}>Continue with Lichess profile</Button>
                <Button onClick={handleChessCom} autoFocus>
                    Continue with Chess.com profile
                </Button>
            </DialogActions>
        </Dialog>
    );
}