/*
Number of games - default
Winrate
Raw diff count, i.e. lose count
Drawrate 
Rating points lose
Diif in winrate compared to average winrate
Most attention required - biggest winrate difference to your average winrate

In the future comparison to the winrate in your rating group
*/

import { OpeningTreeSortingType } from "./OpeningTreeSortingModel"
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { Box, Typography, Tooltip } from "@mui/material"

type OpeningTreeHeaderSortingComponentProps = {
    currentSortType: OpeningTreeSortingType
    sortingTypeDidChange: (newType: OpeningTreeSortingType) => void
    currentTreeDepth: number
    treeDepthDidChange: (newDepth: number) => void
}

export default function OpeningTreeHeaderSortingComponent(props: OpeningTreeHeaderSortingComponentProps) {
    const sortingTypes = Object.values(OpeningTreeSortingType).filter((item) => {
        return isNaN(Number(item));
    });

    const handleTypeChange = (event: SelectChangeEvent) => {
        const new_type = event.target.value as OpeningTreeSortingType;
        props.sortingTypeDidChange(new_type);
    }

    const handleDepthChange = (event: SelectChangeEvent) => {
        const new_depth = event.target.value as unknown as number;
        props.treeDepthDidChange(new_depth);
    }

    var numberArray = [];

    for (var i = 1; i <= 15; i++) {
        numberArray.push(i);
    }

    return (
        <Box flexDirection={"column"}>
            <Typography sx={{ marginLeft: 2 }}>
                Sort by:
            </Typography>
            <Select
                value={props.currentSortType}
                onChange={handleTypeChange}
                variant="outlined"
                sx={{
                    my: 2,
                    ml: 2,
                    width: "auto",
                    color: "white",
                    '.MuiOutlinedInput-notchedOutline': {
                        borderColor: 'primary.main',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'primary.main',
                    },
                    '.MuiSvgIcon-root ': {
                        fill: "white !important",
                    }
                }}
            >
                {sortingTypes.map(type => (<MenuItem value={type} key={type}>{type}</MenuItem>))}

            </Select>
            <Typography sx={{ marginLeft: 2 }}>
                Filter by depth:
            </Typography>
            <Tooltip title='Show all moves at a given depth(ply number)'>
                <Select
                    value={props.currentTreeDepth.toString()}
                    onChange={handleDepthChange}
                    variant="outlined"
                    sx={{
                        my: 2,
                        ml: 2,
                        width: "auto",
                        color: "white",
                        '.MuiOutlinedInput-notchedOutline': {
                            borderColor: 'primary.main',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'primary.main',
                        },
                        '.MuiSvgIcon-root ': {
                            fill: "white !important",
                        }
                    }}
                >
                    {numberArray.map(depth => (<MenuItem value={depth} key={depth}>{depth}</MenuItem>))}

                </Select>
            </Tooltip>
        </Box>
    );
}