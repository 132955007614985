import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from 'react-router-dom';

type LogoComponentProps = {
    callback?: () => void;
}

export default function LogoComponent(props: LogoComponentProps) {
    const navigate = useNavigate();
    const goHome = () => {
        navigate('/');
        if (props.callback !== null && props.callback !== undefined) {
            props.callback();
        }
    }

    return (
        <Button sx={{
            display: 'flex',
            direction: 'row',
            alignItems: 'end',
            px: 1,
            mx: 2,
            borderRadius: 2
        }}
            style={{
                textTransform: 'none'
            }}
            onClick={goHome}>
            <Typography
                display="inline"
                variant="body1"
                color={'text.primary'}
                fontSize={22}
                fontWeight={700}
                style={{ userSelect: "element" }}>
                bookmoves
            </Typography>
            <Typography
                display="inline"
                variant="body1"
                color={'text.secondary'}
                fontSize={20}
                fontWeight={600}
                style={{ userSelect: "element" }}>
                .org
            </Typography>
        </Button>
    );
}