import { useLocation, useNavigate } from "react-router-dom";
import BlogBody from "./Components/BlogBody";
import BlogNavigation from "./Components/BlogNavigation";
import { Grid, Box } from "@mui/material";
import LogoComponent from "../../../common/logo_card/LogoComponent";
import { useEffect } from "react";
import ReactGA from 'react-ga4';
import blogPosts from "../Posts/Posts";
import { getQueryVariable } from "../../../common/helpers"

export default function BlogDetails() {
    const { state, search } = useLocation();
    const navigate = useNavigate();

    var blog: any = [];

    const post_name = getQueryVariable("post", search);
    if (post_name !== null) {
        for (const blog_post in blogPosts) {
            const post = blogPosts[blog_post];
            if (post["search"] === post_name) {
                blog = post;
            }
        }
    } else {
        blog = state.data;
    }

    useEffect(() => {
        ReactGA.event(
            {
                category: 'user-events',
                action: 'blog-post-openned'
            }
        )
    }, []);

    const callback = () => {
        navigate('/')
    }

    return (
        <Box>
            <Box mt={0.5}>
                <LogoComponent callback={callback} />
            </Box>
            <Grid container>
                <Grid item xs={12} md={8}>
                    <BlogBody data={blog} />
                </Grid>
                <Grid item xs={0} md={4}>
                    <BlogNavigation />
                </Grid>
            </Grid>
        </Box>
    );
}