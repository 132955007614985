import { Box, Typography } from "@mui/material";

export default function SearchTitleComponent() {
    return (
        <Box component="section"
            sx={{
                maxWidth: 0.9,
                p: {
                    xs: 3,
                    sm: 8
                },
                paddingBottom: {
                    xs: 2,
                    sm: 2
                }
            }}>
            <Typography variant='h1' style={{ fontWeight: 700 }} letterSpacing={1.5} align='left' sx={{ paddingBottom: 2 }}>
                 Welcome to<br />
                 Chess knowledge database <br />
            </Typography>
            <Typography variant='body1' color={'text.secondary'} align='left' display="inline">
                We offer over 10,000 learning resources from across the internet for every opening position.
                <br />
                Whether you're a chess enthusiast or a grandmaster in the making, our easy-to-use platform lets you explore the best resources for every opening position
                <br />
                Just enter your profile alias or URL from&nbsp;</Typography>
            <Typography variant='body1' color={'primary'} align='left' display="inline">
                Chess.com&nbsp;
            </Typography>
            <Typography variant='body1' color={'text.secondary'} align='left' display="inline">
                or&nbsp;
            </Typography>
            <Typography variant='body1' color={'primary'} align='left' display="inline">
                Lichess.org
            </Typography>
            <Typography variant='body1' color={'text.secondary'} align='left' display="inline">
                , delve into your opening trees, and pick the perfect learning materials for your chosen position.
            </Typography>

        </Box>
    );
}


