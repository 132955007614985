import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import './ColorTab.css'
import { useState } from 'react';

type ColorTabProps = {
    whiteSelected: () => void;
    blackSelected: () => void;
}

export default function ColorTab(props: ColorTabProps) {
    const [selectedColor, setSelectedColor] = useState(true);

    const whiteSelected = () => {
        setSelectedColor(true);
        props.whiteSelected();
    }

    const blackSelected = () => {
        setSelectedColor(false);
        props.blackSelected();
    }

    return (
        <ButtonGroup className='Color-buttons' sx={{m:2}}>
            <Button onClick={whiteSelected} variant={selectedColor ? "contained" : "outlined"}>White</Button>
            <Button onClick={blackSelected} variant={selectedColor ? "outlined" : "contained"}>Black</Button>
        </ButtonGroup>
    );
}