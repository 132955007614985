import { useEffect } from 'react';

declare const window: any;

type AdsenseComponentProps = {
  adKey?: string;
  adSlot: string;
  adFormat: string;
}

export default function AdsenseComponent(props: AdsenseComponentProps) {
  useEffect(() => {
    if (window.adsbygoogle && !window.adsbygoogle.loaded) {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
  }, []);

  return (
    <div>
      <ins className="adsbygoogle"
        style={{ display: 'block' }}
        data-ad-client="ca-pub-5418774851060485"
        data-ad-slot={props.adSlot}
        data-ad-format={props.adFormat}
        data-ad-layout-key={props.adKey ?? ''}>
      </ins>
    </div>
  );
};