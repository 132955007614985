import { Box } from "@mui/material";
import { MaterialModel, MaterialModelType } from "../../../../MaterialModel";

type YoutubeRenderComponentProps = {
    model: MaterialModel;
}

export default function YoutubeRenderComponent(props: YoutubeRenderComponentProps) {
    if (props.model.type !== MaterialModelType.Video) {
        return (<div></div>);
    }
    const url = new URL(props.model.url);
    let youtube_id = url.searchParams.get('v');
    if (youtube_id === null) {
        return (<div></div>);
    }
    if (props.model.timestamp !== null && props.model.timestamp !== undefined) {
        const timestamp = parseInt(props.model.timestamp) - 5;
        youtube_id += '?start=' + timestamp;
    }

    return (
        <Box sx={{my:2, borderRadius: 3, overflow:'clip', position: 'relative', width: "100%", paddingTop: "56.25%"}}>
            <iframe
                style={{ display: 'block', objectFit: 'contain', border: '0px', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0}}
                width="100%"
                height="100%"
                src={`https://www.youtube.com/embed/${youtube_id}`}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title={props.model.title}
            />
        </Box>
    );
}