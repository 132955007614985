import { OpeningTreeNode } from "../../../../../OpeningTreeModel";

/*
Number of games - default
Winrate
Raw diff count, i.e. lose count
Drawrate 
Rating points lose
*/

export enum OpeningTreeSortingType {
    Count = 'Games played',
    MostAttentionRequired = 'Rating loss',
    Winrate = 'Winrate',
    LossCount = 'Games lost',
    Drawrate = 'Draw %',
}

export class OpeningTreeSortingModel {
    nodes: OpeningTreeNode[];
    sortType: OpeningTreeSortingType;

    constructor(nodes: OpeningTreeNode[], sortType: OpeningTreeSortingType) {
        this.nodes = nodes
        this.sortType = sortType
    }

    getSortedNodes(): OpeningTreeNode[] {
        const factory = new NodeSortFactory(this.sortType)
        const sorter = factory.buildSorter()
        sorter.setNodes(this.nodes)
        this.nodes = sorter.getSortedNodes()
        return this.nodes
    }
}

interface NodeSorter {
    setNodes(nodes: OpeningTreeNode[]): void;
    getSortedNodes(): OpeningTreeNode[];
}

class NodeSortFactory {
    sortType: OpeningTreeSortingType;

    constructor(sortType: OpeningTreeSortingType) {
        this.sortType = sortType
    }

    buildSorter(): NodeSorter {
        switch (this.sortType) {
            case OpeningTreeSortingType.Count:
                return new CountSorter()
            case OpeningTreeSortingType.Winrate:
                return new WinrateSorter()
            case OpeningTreeSortingType.LossCount:
                return new LossCountSorter()
            case OpeningTreeSortingType.Drawrate:
                return new DrawrateSorter()
            case OpeningTreeSortingType.MostAttentionRequired:
                return new MostAttentionRequiredSorter()
        }
    }
}

class CountSorter implements NodeSorter {
    nodes: OpeningTreeNode[];

    constructor() {
        this.nodes = []
    }

    setNodes(nodes: OpeningTreeNode[]): void {
        this.nodes = nodes
    }
    getSortedNodes(): OpeningTreeNode[] {
        this.nodes.sort((a, b) => (a.count < b.count ? 1 : -1));
        return this.nodes
    }
}

class WinrateSorter implements NodeSorter {
    nodes: OpeningTreeNode[];

    constructor() {
        this.nodes = []
    }

    setNodes(nodes: OpeningTreeNode[]): void {
        this.nodes = nodes
    }
    getSortedNodes(): OpeningTreeNode[] {
        this.nodes.sort((a, b) => (a.winrate < b.winrate ? 1: -1));
        return this.nodes
    }
}

class LossCountSorter implements NodeSorter {
    nodes: OpeningTreeNode[];

    constructor() {
        this.nodes = []
    }

    setNodes(nodes: OpeningTreeNode[]): void {
        this.nodes = nodes
    }
    getSortedNodes(): OpeningTreeNode[] {
        this.nodes.sort((a, b) => (a.loserate() * a.count < b.loserate() * b.count ? 1 : -1));
        return this.nodes
    }
}

class DrawrateSorter implements NodeSorter {
    nodes: OpeningTreeNode[];

    constructor() {
        this.nodes = []
    }

    setNodes(nodes: OpeningTreeNode[]): void {
        this.nodes = nodes
    }
    getSortedNodes(): OpeningTreeNode[] {
        this.nodes.sort((a, b) => (a.drawrate < b.drawrate ? 1 : -1));
        return this.nodes
    }
}

class MostAttentionRequiredSorter implements NodeSorter { // where difference in loss to win is the greatest
    nodes: OpeningTreeNode[];

    constructor() {
        this.nodes = []
    }

    setNodes(nodes: OpeningTreeNode[]): void {
        this.nodes = nodes
    }
    getSortedNodes(): OpeningTreeNode[] {
        this.nodes.sort((a, b) => (
            (a.loserate() - a.winrate) * a.count < (b.loserate() - b.winrate) * b.count ? 1 : -1)
        );
        return this.nodes
    }
}
