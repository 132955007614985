import { Box, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import MaterialTypeDropDown from "../MaterialType/MaterialTypeDropDown";
import ContributionSuccessDialog from "../Alert/ContributionSuccess";
import ContributionValidationDialog from "../Alert/ContributionValidation";
import { useState, useRef } from 'react';

export default function ContributionFormComponent() {
    const [loading, setLoading] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const titleRef = useRef<any>()
    const linkRef = useRef<any>()
    const [validationFailed, setValidationFailed] = useState(false);
    const [materialType, setMaterialType] = useState("");

    const validateSubmission = (): Boolean => {
        const link = linkRef?.current.value;
        return materialType.length > 0 && link.length > 0;
    }

    const showValidationPopup = () => {
        setValidationFailed(true);
        setLoading(false);
    }

    const handleSubmit = () => {
        setLoading(true);

        if (validateSubmission() === false) {
            showValidationPopup();
            return;
        }
        const title = titleRef?.current.value;
        const link = linkRef?.current.value;
        var url = process.env.REACT_APP_API_URI + "/candidate_sub/?title=" + title + "&url=" + link + "&material_type=" + materialType;
        fetch(url)
            .then(res => res.json())
            .then(
                (result) => {
                    setLoading(false);
                    setAlertOpen(true);
                },
                (error) => {
                    setLoading(false);
                    setAlertOpen(true);
                }
            )
    }

    const materialTypeSelected = (materialSelected: string) => {
        setMaterialType(materialSelected);
    }

    const handleCloseAlert = () => {
        setAlertOpen(false);
    }

    const handleCloseValidation = () => {
        setValidationFailed(false);
    }

    return (
        <Box
            component="section"
            sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                bgcolor: 'background.paper',
                p: 4,
                borderRadius: 3,
            }}>
            <MaterialTypeDropDown materialSelected={materialTypeSelected} />
            <TextField
                label="Suggested title"
                variant="outlined"
                inputRef={titleRef}
                sx={{ marginBottom: 2, minWidth: {
                    sm: 325,
                    md: 475,
                } }} />
            <TextField
                required
                label="Link"
                variant="outlined"
                inputMode='url'
                inputRef={linkRef}
                sx={{ marginBottom: 2, minWidth: {
                    sm: 325,
                    md: 475,
                } }} />
            <LoadingButton
                onClick={handleSubmit}
                loading={loading}
                variant="contained"
                sx={{ maxWidth: 120 }}
            >
                <span>Submit</span>
            </LoadingButton>
            <ContributionSuccessDialog open={alertOpen} closeCallback={handleCloseAlert} />
            <ContributionValidationDialog open={validationFailed} closeCallback={handleCloseValidation} />
        </Box>
    );
}