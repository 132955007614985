import MaterialModelRender from "./factory/MaterialModelRender";
import { MaterialModel } from "../../../../../MaterialModel";
import { Grid } from "@mui/material";

// Grid for hosting renders of specific materials

type RightPaneMaterialGridProps = {
    adKey: string;
    materials: Array<MaterialModel>;
    modelSelected?: (model: MaterialModel) => void;
}

export default function RightPaneMaterialGrid(props: RightPaneMaterialGridProps) {
    var materialRenders = [];
    for (const material_index in props.materials) {
        const material = props.materials[material_index];
        materialRenders.push(
            <Grid item xs={12} sm={6} md={4} key={material.url}>
                <MaterialModelRender model={material} key={material.url} modelSelected={props.modelSelected} />
            </Grid>
        );
    }
    
    return (
        <Grid container spacing={2}>
            {materialRenders}
        </Grid>
    );
}
