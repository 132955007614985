export enum GameType {
    Bullet = 'bullet',
    Blitz = 'blitz',
    Rapid = 'rapid',
    Classical = 'classical',
}

export enum NumberOfGames {
    last50 = 'last50',
    last100 = 'last100',
    last250 = 'last250',
    last1000 = 'last1000',
}

export enum TimeSince {
    last7Days = 'last7Days',
    last30Days = 'last30Days',
    last90Days = 'last90Days',
    last180Days = 'last180Days',
    last360Days = 'last360Days',
}

export class SearchParamsModel {
    gameTypes: Array<GameType>;
    numberOfGames: NumberOfGames;
    timeSince: TimeSince;
    onlyRated: boolean;

    constructor() {
        this.gameTypes = Array<GameType>();
        this.numberOfGames = NumberOfGames.last250;
        this.timeSince = TimeSince.last180Days;
        this.onlyRated = false;
    }

    copy(): SearchParamsModel {
        var newModel = new SearchParamsModel()
        newModel.gameTypes = this.gameTypes
        newModel.numberOfGames = this.numberOfGames
        newModel.timeSince = this.timeSince
        newModel.onlyRated = this.onlyRated
        return newModel
    }

    getGameTypes(): String {
        if (this.gameTypes.length === 0) {
            return 'bullet,blitz,rapid,classical'
        } else {
            return this.gameTypes.toString()
        }
    }
}
