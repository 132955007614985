import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { GameType, NumberOfGames, SearchParamsModel, TimeSince } from './SearchParamsModel';
import { useState } from 'react';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, Tooltip } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

// the tab for all search components: max games, time since, time format, rated, etc

/*
Need search model here
Model takes params
Send back through the prop
Add it to request on search
*/

type SearchParamTabProps = {
    modelDidChange: (model: SearchParamsModel) => void
}

export default function SearchParamTab(props: SearchParamTabProps) {
    const gameTypeButtons = [GameType.Bullet, GameType.Blitz, GameType.Rapid, GameType.Classical]
    const numberOfGamesButtons = [NumberOfGames.last50, NumberOfGames.last100, NumberOfGames.last250, NumberOfGames.last1000]
    const timeSinceSelectedButtons = [TimeSince.last7Days, TimeSince.last30Days, TimeSince.last90Days, TimeSince.last180Days, TimeSince.last360Days]
    const [searchParamsModel, setSearchParamsModel] = useState(new SearchParamsModel());

    const gameTypeSelected = (gameType: GameType) => {
        var newModel = searchParamsModel.copy()
        if (newModel.gameTypes.includes(gameType)) {
            const index = newModel.gameTypes.indexOf(gameType, 0);
            if (index > -1) {
                newModel.gameTypes.splice(index, 1);
            }
        } else {
            newModel.gameTypes.push(gameType)
        }
        setSearchParamsModel(newModel)
        props.modelDidChange(newModel)
    }

    const numberOfGamesSeleted = (numberOfGames: NumberOfGames) => {
        var newModel = searchParamsModel.copy()
        newModel.numberOfGames = numberOfGames
        setSearchParamsModel(newModel)
        props.modelDidChange(newModel)
    }

    const timeSinceSelected = (timeSince: TimeSince) => {
        var newModel = searchParamsModel.copy()
        newModel.timeSince = timeSince
        setSearchParamsModel(newModel)
        props.modelDidChange(newModel)
    }

    const onlyRankedToggled = () => {
        var newModel = searchParamsModel.copy()
        newModel.onlyRated = !searchParamsModel.onlyRated
        setSearchParamsModel(newModel)
        props.modelDidChange(newModel)
    }

    return (
        <Box sx={{ borderRadius: 3, maxWidth: { sm: 0.8, xs: 0.9, md: 0.7 }, overflow: 'clip', mx: 3 }}>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMore color='primary' sx={{ marginRight: 0.5 }} />}>
                    <Typography variant='h6' sx={{ marginLeft: 2 }}>
                        Advanced search options
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box sx={{
                        p: 1,
                    }}>
                        <Typography>
                            Filter by time control. By default searches all games:
                        </Typography>
                        <ButtonGroup style={{ flexWrap: "wrap" }}>
                            {
                                gameTypeButtons.map(button => (
                                    <Button
                                        key={button}
                                        onClick={() => gameTypeSelected(button)}
                                        variant={searchParamsModel.gameTypes.includes(button) ? 'contained' : 'outlined'}>
                                        {button}
                                    </Button>
                                ))
                            }
                        </ButtonGroup>
                    </Box>
                    <Box sx={{ p: 1 }}>
                        <Typography>
                            Number of games to include:
                        </Typography>
                        <ButtonGroup style={{ flexWrap: "wrap" }}>
                            {
                                numberOfGamesButtons.map(button => (
                                    <Tooltip key={button} title={getTooltipFromEnum(button)}>
                                        <Button
                                            onClick={() => numberOfGamesSeleted(button)}
                                            variant={searchParamsModel.numberOfGames === button ? 'contained' : 'outlined'}>
                                            {gamesStringFromEnum(button)}
                                        </Button>
                                    </Tooltip>
                                ))
                            }
                        </ButtonGroup>
                    </Box>
                    <Box sx={{ p: 1 }}>
                        <Typography>
                            Include games since:
                        </Typography>
                        <ButtonGroup style={{ flexWrap: "wrap" }}>
                            {
                                timeSinceSelectedButtons.map(button => (
                                    <Button
                                        key={button}
                                        onClick={() => timeSinceSelected(button)}
                                        variant={searchParamsModel.timeSince === button ? 'contained' : 'outlined'}>
                                        {timeSinceStringFromEnum(button)}
                                    </Button>
                                ))
                            }
                        </ButtonGroup>
                    </Box>
                    <Box sx={{ p: 1 }}>
                        <Typography>
                            Toggle to include only rated games:
                        </Typography>
                        <Button
                            key="only_rated"
                            onClick={onlyRankedToggled}
                            variant={searchParamsModel.onlyRated ? 'contained' : 'outlined'}>
                            {searchParamsModel.onlyRated ? "Only rated games included" : "Include only rated games"}
                        </Button>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
}

function timeSinceStringFromEnum(timeSince: TimeSince) {
    switch (timeSince) {
        case TimeSince.last7Days:
            return "7 days ago";
        case TimeSince.last30Days:
            return "30 days ago";
        case TimeSince.last90Days:
            return "90 days ago";
        case TimeSince.last180Days:
            return "180 days ago";
        case TimeSince.last360Days:
            return "360 days ago";
    }
}

function gamesStringFromEnum(timeSince: NumberOfGames) {
    switch (timeSince) {
        case NumberOfGames.last50:
            return "last 50 games";
        case NumberOfGames.last100:
            return "last 100 games";
        case NumberOfGames.last250:
            return "last 250 games";
        case NumberOfGames.last1000:
            return "last 1000 games";
    }
}

function getTooltipFromEnum(numberOfGames: NumberOfGames): String {
    switch (numberOfGames) {
        case NumberOfGames.last50:
            return "Takes a few seconds";
        case NumberOfGames.last100:
            return "Takes ~10 seconds";
        case NumberOfGames.last250:
            return "Takes 15-20 seconds";
        case NumberOfGames.last1000:
            return "Might take a minute";
    }
}