import { OpeningTreeNode } from '../../../../OpeningTreeModel';
import { Typography, Box } from '@mui/material';
import { ChevronRight, ArrowDropDown, ArrowDropUp, Balance } from '@mui/icons-material';

type OpeningTreeBarProps = {
    node: OpeningTreeNode;
    identifier: string;
    moveCallback: (san: string) => void;
    onClick: (identifier: string, node: OpeningTreeNode) => void;
    displayFullPGN: boolean;
};

export default function OpeningTreeBar(props: OpeningTreeBarProps) {
    const onClick = () => {
        props.onClick(props.identifier, props.node);
    }
    return (
        <Box
            onClick={onClick}
            sx={{
                flexDirection: 'row',
                display: 'flex',
                alignItems: 'center',
                pl: 4,
                "&:hover": {
                    backgroundColor: '#45494F',
                    borderRadius: 3,
                }
            }}>
            {/* content of the bar */}
            <Box
                sx={{
                    flexDirection: 'column',
                    display: 'flex',
                    alignItems: 'center',
                    width: "100%"
                }}>
                {/* top text */}
                <Box
                    sx={{
                        flexDirection: 'row',
                        display: 'flex',
                        width: "100%"
                    }}>
                    <Typography sx={{mt: 1 }}>
                        {props.node.move_number}. {props.displayFullPGN ? props.node.pgn : props.node.move.san}
                    </Typography>
                    <Typography sx={{ marginLeft: 'auto', mt: 1 }}>
                        {props.node.count}
                    </Typography>
                </Box>
                {/* percentages and icons */}
                <Box
                    sx={{
                        flexDirection: 'row',
                        display: 'flex',
                        alignItems: 'center',
                        width: "100%"
                    }}>
                    {winrateIconAndText(props.node)}
                    {drawIconAndText(props.node)}
                    {lossIconAndText(props.node)}
                </Box>
                {/* bar body */}
                {bar(props.node)}
                {/* number of games at the bottom*/}
                {bottomText(props.node)}
            </Box>
            {/* chevron container */}
            <Box>
                <ChevronRight fontSize='large' sx = {{color: "text.secondary"}}/>
            </Box>
        </Box>
    );
}

function bottomText(node: OpeningTreeNode) {
    let wins = null;
    let draws = null;
    let losses = null;
    if (node.wins_count() > 0) {
        wins = (
            <Typography
                sx={{
                    m: 1,
                }}
                variant="body2"
                color={'text.secondary'}
                fontSize={13}>
                {node.wins_count()}
            </Typography>
        );
    }
    if (node.drawcount() > 0) {
        draws = (
            <Typography
                sx={{
                    ml: 'auto',
                    my: 1
                }}
                variant="body2"
                color={'text.secondary'}
                fontSize={13}>
                {node.drawcount()}
            </Typography>
        );
    }
    if (node.loss_count() > 0) {
        losses = (
            <Typography
                sx={{
                    my: 1,
                    ml: 'auto',
                    mr: 1,
                }}
                variant="body2"
                color={'text.secondary'}
                fontSize={13}>
                {node.loss_count()}
            </Typography>
        );
    }
    return (
        <Box
            sx={{
                flexDirection: 'row',
                display: 'flex',
                alignItems: 'center',
                width: "100%"
            }}>
            {wins}
            {draws}
            {losses}
        </Box>
    );
}

function winrateIconAndText(node: OpeningTreeNode) {
    if (node.wins_count() === 0) {
        return null;
    }
    return (
        <Box
            sx={{
                flexDirection: 'row',
                display: 'flex',
                alignItems: 'center',
                mb: 1
            }}>
            <ArrowDropUp color="success" sx={{ border: 2, borderRadius: 2, mr: 1 }} />
            <Typography variant="body1" color={'success.main'} fontSize={12} style={{ fontWeight: 800 }}>
                {(node.winrate * 100).toFixed(0) + '%'}
            </Typography>
        </Box>
    );
}

function lossIconAndText(node: OpeningTreeNode) {
    if (node.loss_count() === 0) {
        return null;
    }
    return (
        <Box
            sx={{
                flexDirection: 'row',
                display: 'flex',
                alignItems: 'center',
                ml: 'auto',
                mb: 1
            }}>
            <ArrowDropDown color="error" sx={{ border: 2, borderRadius: 2, mr: 1 }} />
            <Typography variant="body1" color={'error.main'} fontSize={12} style={{ fontWeight: 800 }}>
                {(node.loserate() * 100).toFixed(0) + '%'}
            </Typography>
        </Box>);
}

function drawIconAndText(node: OpeningTreeNode) {
    if (node.drawcount() === 0) {
        return null;
    }
    return (
        <Box
            sx={{
                flexDirection: 'row',
                display: 'flex',
                alignItems: 'center',
                mb: 1,
                ml: 'auto',
            }}>
            <Balance sx={{ color: "text.secondary", border: 2, borderRadius: 2, mr: 1}} />
            <Typography variant="body1" color={'text.secondary'} fontSize={12} style={{ fontWeight: 800 }}>
                {(node.drawrate * 100).toFixed(0) + '%'}
            </Typography>
        </Box>
    );
}

function bar(node: OpeningTreeNode) {
    let whiteBar = null;
    let drawBar = null;
    let blackBar = null;

    if (node.wins_count() > 0) {
        whiteBar = (
            <Box
                sx={{
                    width: ((node.winrate * 100).toFixed(0) + '%'),
                    height: 12,
                    backgroundColor: "#F0D9B5"
                }} />
        );
    }
    if (node.drawcount() > 0) {
        drawBar = (
            <Box
                sx={{
                    width: ((node.drawrate * 100).toFixed(0) + '%'),
                    height: 12,
                    backgroundColor: "#B58863"
                }} />
        );
    }
    if (node.loss_count() > 0) {
        blackBar = (
            <Box
                sx={{
                    width: ((node.loserate() * 100).toFixed(0) + '%'),
                    height: 12,
                    backgroundColor: "#16161a"
                }} />
        );
    }

    return (
        <Box
            sx={{
                flexDirection: 'row',
                display: 'flex',
                alignItems: 'center',
                width: "100%",
                borderRadius: 3,
                overflow: 'clip'
            }}>
            {whiteBar}
            {drawBar}
            {blackBar}
        </Box>
    );
}