import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import IconCardComponent from '../../../common/icon_card/IconCardComponent';

export default function Roadmap() {
  return (
    <Timeline position="alternate">
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: 6 }}>
          <IconCardComponent
            iconType='Evals'
            title='Stockfish evaluations'
            text='We are working on adding objective evaluation to every position in your opening tree. To help you elimanate even more blind spots.' />
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: 6 }}>
          <IconCardComponent
            iconType='Compare'
            title='Comparing to masters and your rating group'
            text='This feature will allow your too see how you perform in certain opening positions compared to masters or your rating group. This will highlight problematic areas even better.' />
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: 6 }}>
          <IconCardComponent
            iconType='Account'
            title='Create website account'
            text='We are working on getting you option to sign in to website, so that you do not need to fetch games from chess websites every time. You will also be able to save openeings that you are working on.' />
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot />
        </TimelineSeparator>
        <TimelineContent sx={{ py: 6 }}>
          <IconCardComponent
            iconType='Up'
            title='Endgame builder'
            text='So far we only focused on openings and building your repertoire, but there is so much more to explore in the chess world.' />
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
}