import { OpeningTreeNode } from "../../../../OpeningTreeModel";
import OpeningTreeBar from "../bar/OpeningTreeBar";
import { Box } from "@mui/material";

type DepthTreeTableProps = {
    nodes: OpeningTreeNode[];
    moveCallback: (pgn: string) => void;
}

export default function DepthTreeTable(props: DepthTreeTableProps) {
    let resultBars = [];

    const onClick = (identifier: string, node: OpeningTreeNode) => {
        props.moveCallback(identifier);
    }

    for (const key in props.nodes) {
        const san = props.nodes[key].move.san;
        const pgn = props.nodes[key].pgn;
        const identifier = pgn + san + props.nodes[key].ply.toString();
        resultBars.push(
            <OpeningTreeBar
                node={props.nodes[key]}
                identifier={pgn}
                onClick={onClick}
                moveCallback={props.moveCallback}
                key={identifier}
                displayFullPGN={true}
            />);
    }

    return (
        <Box sx={{
            flexDirection: 'column',
            display: 'flex'
        }}>
            {resultBars}
        </Box>
    );
}