import React, { useState } from 'react';
import './Home.css';
import { Box } from '@mui/material';
import { Tab } from '@mui/material';
import { Tabs } from '@mui/material';
import About from './About/About';
import Support from './Support/Support';
import Contribute from './Contribute/Contribute';
import Search from './Search/Search';
import LogoComponent from '../common/logo_card/LogoComponent';
import Blog from './Blog/Blog';

function Home() {
    const [value, setValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const callback = () => {
        setValue(0);
    }

    return (
        <div className="Home">
            <Box sx={{
                display: "flex",
                direction: "row",
                alignItems: 'center',
                flexWrap: 'wrap',
            }}>
                <LogoComponent callback={callback} />
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="scrollable"
                    scrollButtons="auto">
                    <Tab label="Search" />
                    <Tab label="Contribute" />
                    <Tab label="Support" />
                    <Tab label="About" />
                    <Tab label="Blog" />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <Search />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Contribute />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Support />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <About />
            </TabPanel>
            <TabPanel value={value} index={4}>
                <Blog />
            </TabPanel>
        </div>
    );
}

export default Home;

type TabPanelProps = {
    children: JSX.Element,
    value: number,
    index: number
}

export function TabPanel(props: TabPanelProps) {
    return (
        <div
            role="tabpanel"
            hidden={props.value !== props.index}
        >
            {props.children}
        </div>
    );
}