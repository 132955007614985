import './OpeningTreeHeader.css';
import OpeningTreeHeaderSortingComponent from './sorting/OpeningTreeHeaderSortingComponent';
import { OpeningTreeSortingType } from "./sorting/OpeningTreeSortingModel"
import { Accordion, Box, Typography, AccordionSummary, Button, AccordionDetails } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import ColorTab from '../../../color_tab/ColorTab';

type OpeningTreeHeaderProps = {
    movesCount: number;

    currentMoves?: string;
    movesDidChange: (newVariation: string) => void;

    currentSortType?: OpeningTreeSortingType;
    sortingTypeDidChange?: (newType: OpeningTreeSortingType) => void;
    currentTreeDepth: number;
    treeDepthDidChange?: (treeDepth: number) => void;

    whiteOpeningSelected?: () => void;
    blackOpeningSelected?: () => void;

    currentOpening?: string;
}

export default function OpeningTreeHeader(props: OpeningTreeHeaderProps) {
    const sortingTypeDidChange = (newType: OpeningTreeSortingType) => {
        if (props.sortingTypeDidChange !== null && props.sortingTypeDidChange !== undefined) {
            props.sortingTypeDidChange!(newType)
        }
    }

    const whiteOpeningSelected = () => {
        if (props.whiteOpeningSelected !== null && props.whiteOpeningSelected !== undefined) {
            props.whiteOpeningSelected()
        }
    }

    const blackOpeningSelected = () => {
        if (props.blackOpeningSelected !== null && props.blackOpeningSelected !== undefined) {
            props.blackOpeningSelected()
        }
    }

    const treeDepthDidChange = (treeDepth: number) => {
        if (props.treeDepthDidChange !== null && props.treeDepthDidChange !== undefined) {
            props.treeDepthDidChange(treeDepth)
        }
    }

    let accordion = null;
    let table_header = null;
    if (props.currentSortType != null) {
        accordion = (
            <Box component="section"
                sx={{
                    flexDirection: 'column',
                    display: 'flex',
                    bgcolor: 'background.paper',
                    borderRadius: 3,
                    mb: 2,
                    overflow: 'clip'
                }}>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore color='primary' sx={{ marginRight: 0.5 }} />}>
                        <Typography variant='h6' sx={{ marginLeft: 2 }}>
                            Filter and sort
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography sx={{ marginLeft: 2 }}>
                            Showing opening tree for:
                        </Typography>
                        <ColorTab
                            whiteSelected={whiteOpeningSelected}
                            blackSelected={blackOpeningSelected}
                        />
                        <OpeningTreeHeaderSortingComponent
                            currentTreeDepth={props.currentTreeDepth}
                            currentSortType={props.currentSortType}
                            sortingTypeDidChange={sortingTypeDidChange} 
                            treeDepthDidChange={treeDepthDidChange}/>
                    </AccordionDetails>
                </Accordion>
            </Box>
        )
        if (props.movesCount > 0) {
            table_header = (
                <Box sx={{
                    flexDirection: 'row',
                    display: 'flex',
                    alignItems: 'left',
                    bgcolor: 'background.paper',
                    borderRadius: 3,
                    mb: 2,
                    overflow: 'clip'
                }}>
                    <Typography sx={{ marginLeft: 4, my: 2 }}>
                        Move
                    </Typography>
                    <Typography sx={{ marginLeft: 'auto', marginRight: 4, my: 2 }}>
                        Total Games
                    </Typography>
                </Box>
            )
        }

    }

    let opening_string = "Opening: Starting position"
    if (props.currentOpening !== undefined && props.currentOpening !== '') {
        opening_string = "Opening: " + props.currentOpening;
    }

    return (
        <Box>
            {accordion}
            <Box component="section"
                sx={{
                    flexDirection: 'column',
                    display: 'flex',
                    alignItems: 'left',
                    bgcolor: 'background.paper',
                    borderRadius: 3,
                    mb: 2,
                    overflow: 'clip',
                    pb: 2
                }}>
                <Typography sx={{ marginLeft: 4, marginTop: 2 }}>
                    {opening_string}
                </Typography>
                {/*TODO: Replace with clickable component*/}
                {clickablePGNComponent(props.currentMoves ?? "", props.movesDidChange)}
            </Box>
            {table_header}
        </Box>
    );
}

function clickablePGNComponent(moves: string, movesDidChange: (newVariation: string) => void) {
    if (moves === '' || moves === ' ') {
        return null;
    }
    const array = moves.split(' ')
    const elements: React.ReactElement[] = [];

    for (var i = 0; i < array.length; i++) {
        const string = array[i]

        if (string.match(/^\d/)) {
            elements.push(
                <Typography
                    key={string}
                    display="inline"
                    variant="body1"
                    color={'text.secondary'}
                    fontSize={14}
                    mr={0.5}
                    mt={0.1}>
                    {string}
                </Typography>
            )
        } else {
            const sub_array = array.slice(0, i + 1);
            const result = sub_array.join(' ')
            elements.push(
                getButton(string, result, movesDidChange)
            )
        }
    }

    return (
        <Box sx={{
            marginLeft: 4,
            flexDirection: 'row',
            display: 'flex',
            alignItems: 'centre',
            flexWrap: 'wrap',
        }} >
            {elements}
        </Box>
    );
}

function getButton(move: string, pgn: string, movesDidChange: (newVariation: string) => void) {
    return (
        <Button
            key={move}
            onClick={() => {
                movesDidChange(pgn);
            }}
            sx={{
                minHeight: 0,
                minWidth: 0,
                padding: 0,
                alignItems: 'centre',
                fontSize: 14,
                mr: 0.5
            }}
            style={{
                textTransform: 'none'
            }}
            variant="text">
            {move}
        </Button>
    );
}