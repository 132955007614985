import { Box, Typography } from '@mui/material';
import './About.css'
import FeedbackComponent from './Feedback/FeedbackComponent';
import Roadmap from "./Roadmap/Roadmap";
import ReactGA from 'react-ga4'
import { useEffect } from 'react';

export default function About() {
    useEffect(() => {
        ReactGA.event(
            {
                category: 'user-events',
                action: 'main-page-about'
            }
        )
      }, []);

    return (
        <Box component="section" sx={{
            p: {
                xs: 3,
                sm: 8
            },
            paddingBottom: {
                xs: 2,
                sm: 2
            },
        }} className='about-box'>
            <Typography
                variant='h2'
                style={{ fontWeight: 700 }}
                letterSpacing={1.5}
                align='left'
                sx={{ paddingBottom: 2 }}>
                Our project, our plans, our passion.
            </Typography>
            <Box
                component="section"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}>
                <Box component="section"
                    sx={{
                        p: 2,
                        paddingLeft: 3,
                        flexDirection: 'Column',
                        display: 'flex',
                        alignItems: 'left',
                        bgcolor: 'background.paper',
                        borderRadius: 3,
                        mb: 4
                    }}>
                    <Typography variant="h6" align='left'>
                        How it all started
                    </Typography>
                    <Typography
                        variant='body1'
                        color={'text.secondary'}
                        align='left'>
                        As many other chess players out there I wanted to improve my opening repertoire, <br />
                        but I noticed that despite abundance of courses, videos, books and studies available online <br />
                        I couldn't actually figure out what I need to study in a limited time that I have. <br />
                        I wanted a tool that would look at my chess profile and show me my worst opening by winrate or raiting lost <br />
                        and recommend the best learning material for that specific opening or opening position. <br />
                    </Typography>
                </Box>
                <Box component="section"
                    sx={{
                        p: 2,
                        paddingLeft: 3,
                        flexDirection: 'Column',
                        display: 'flex',
                        alignItems: 'left',
                        bgcolor: 'background.paper',
                        borderRadius: 3,
                        mb: 4
                    }}>
                    <Typography variant="h6" align='left'>
                        So I built it and...
                    </Typography>
                    <Typography
                        variant='body1'
                        color={'text.secondary'}
                        align='left'>
                        I found it extremely useful in building and improving my chess repertoire <br />
                        and I wanted to share it with the chess communty, <br />
                        hoping that you will find it as useful as I did. <br />
                    </Typography>
                </Box>
                <Box component="section"
                    sx={{
                        p: 2,
                        paddingLeft: 3,
                        flexDirection: 'Column',
                        display: 'flex',
                        alignItems: 'left',
                        bgcolor: 'background.paper',
                        borderRadius: 3,
                        mb: 4
                    }}>
                    <Typography variant="h6" align='left'>
                        Help us to make it better
                    </Typography>
                    <Typography
                        variant='body1'
                        color={'text.secondary'}
                        align='left'>
                        I strongly encourage you to contribute to the project's database. <br />
                        Especially if you ever came across a learning material that you found extremely useful. < br />
                        It will help to improve other players. < br />
                    </Typography>
                </Box>
                <Typography variant='h5' align='center'>
                    Meanwhile here are a few things that we are cooking in background:
                </Typography>
                <Roadmap />
                <FeedbackComponent />
            </Box>
        </Box>
    );
}