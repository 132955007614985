import { Box, Typography } from "@mui/material";
import { QuestionMark, AddBusiness, CardMembership, SetMeal, CompareArrows, AccountCircle, TrendingUp, ImportContacts, PlayCircleOutlined, School, ReadMore } from "@mui/icons-material";


type IconCardComponentProps = {
    iconType: 'Book' | 'Video' | 'School' | 'More' | 'Credit_Card' | 'Ad_Block' | 'Evals' | 'Compare' | 'Account' | 'Up' | 'Question';
    text: string;
    title: string;
    href?: string;
}

export default function IconCardComponent(props: IconCardComponentProps) {
    var icon = null;

    switch (props.iconType) {
        case 'Question':
            icon = <QuestionMark fontSize='large' />;
            break;
        case 'Book':
            icon = <ImportContacts fontSize='large' />;
            break;
        case 'Video':
            icon = <PlayCircleOutlined fontSize='large' />;
            break;
        case 'More':
            icon = <ReadMore fontSize='large' />;
            break;
        case 'School':
            icon = <School fontSize='large' />;
            break;
        case 'Credit_Card':
            icon = <CardMembership fontSize='large' />;
            break;
        case 'Ad_Block':
            icon = <AddBusiness fontSize='large' />;
            break;
        case 'Evals':
            icon = <SetMeal fontSize='large' />;
            break;
        case 'Compare':
            icon = <CompareArrows fontSize='large' />;
            break;
        case 'Account':
            icon = <AccountCircle fontSize='large' />;
            break;
        case 'Up':
            icon = <TrendingUp fontSize='large' />;
            break;
    }

    const onClick = () => {
        if (props.href !== null && props.href !== undefined)
            openInNewTab(props.href)
    }

    const openInNewTab = (url: string) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    return (
        <Box component="section"
            onClick={onClick}
            sx={{
                p: 2,
                paddingLeft: 3,
                flexDirection: 'row',
                display: 'flex',
                alignItems: 'center',
                bgcolor: 'background.paper',
                borderRadius: 3,
            }}>
            <Box
                bgcolor={'warning.main'}
                sx={{
                    borderRadius: 3,
                    p: 1,
                    alignItems: 'center',
                    display: 'flex'
                }}>
                {icon}
            </Box>
            <Box>
                <Typography variant="h6" align='left' sx={{ paddingLeft: 3 }}>
                    {props.title}
                </Typography>
                <Typography variant="body1" color={'text.secondary'} align='left' sx={{ paddingLeft: 3 }}>
                    {props.text}
                </Typography>
            </Box>
        </Box>
    );
}