import blogPosts from "./Posts/Posts";
import BlogTile from "./Components/BlogTile";
import { Grid, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import ReactGA from 'react-ga4';

// Main component for the blog

// Landing page is short version of the blogpost
// links into detailed blog post:

// detailed view
// 2 panes:
// left pane: content of the blog
// right pane: list of all blog posts

// content is coming from json files in a folder
export default function Blog() {
    useEffect(() => {
        ReactGA.event(
            {
                category: 'user-events',
                action: 'main-page-blog'
            }
        )
    }, []);
    const navigate = useNavigate();
    const onSelect = (data: any) => {
        navigate('/blog_post',
            {
                state: {
                    data: data,
                }
            });
    }

    const posts = blogPosts.map((post) => gridItemFromBlog(post, onSelect))

    return (
        <Box>
            <Grid container spacing={2}
                direction="column"
                alignItems="center"
                justifyContent="center"
                my={2}>
                {posts}
            </Grid>
        </Box>
    );
}

function gridItemFromBlog(blog: any, onSelect: (data: any) => void) {
    const onClick = () => {
        onSelect(blog)
    }
    return (
        <Grid item xs={12} width={{lg: "60%", sx: "90%"}} key={blog["title"]} mx={2}>
            <Box onClick={onClick}>
                <BlogTile
                    title={blog["title"]}
                    body={blog["body"]["paragraph"]}
                    img={blog["small_image"]} 
                    date={blog["date"]}/>
            </Box>
        </Grid>
    );
}