import { Box, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField, Tooltip } from "@mui/material";
import { Replay, Undo, Redo, FileUpload, Cached } from "@mui/icons-material";
import { Chess } from "chess.js";
import { useState, useRef } from "react";
import { ReactComponent as ChessLogo } from './chesscom_logo.svg';
import { ReactComponent as LichessLogo } from './lichess_logo.svg';

// component with controls under chessboard

// rotate board, reset game, prev move, next move, import fen/pgn

type ChessboardControlsComponentProps = {
    previousMove: () => void;
    nextMove: () => void;
    resetBoard: () => void;
    setPgn: (pgn: string) => void;
    flipBoard: () => void;
    analyseOnChessCom: () => void;
    analyseOnLichessOrg: () => void;
}

export default function ChessboardControlsComponent(props: ChessboardControlsComponentProps) {
    const [isPGNPopupOpen, setIsPGNPopupOpen] = useState(false);
    const openPGNPopup = () => {
        setIsPGNPopupOpen(true);
    }
    const closePGNPopup = () => {
        setIsPGNPopupOpen(false);
    }
    return (
        <Box m={2} display={'flex'} flexDirection={'row'} width={'100%'}>
            {previousMoveButton(props.previousMove)}
            {nextMoveButton(props.nextMove)}
            {resetBoardButton(props.resetBoard)}
            {flipBoard(props.flipBoard)}
            {importFromPGNButton(openPGNPopup)}
            {analyseOnChessComButton(props.analyseOnChessCom)}
            {analyseOnLichessOrgButton(props.analyseOnLichessOrg)}
            <ImportFromPGNPopup
                isOpen={isPGNPopupOpen}
                PGNImportCallback={props.setPgn}
                closeCallback={closePGNPopup} />
        </Box>
    );
}

function analyseOnChessComButton(action: () => void) {
    return (
        <Tooltip title='Analyse on Chess.com'>
            <IconButton onClick={action} color="primary">
                <ChessLogo />
            </IconButton>
        </Tooltip>
    );
}

function analyseOnLichessOrgButton(action: () => void) {
    return (
        <Tooltip title='Analyse on Lichess.org'>
            <IconButton onClick={action} color="primary">
                <LichessLogo />
            </IconButton>
        </Tooltip>
    );
}

function previousMoveButton(action: () => void) {
    return (
        <Tooltip title='Previous Move'>
            <IconButton onClick={action} color="primary">
                <Undo fontSize='large' />
            </IconButton>
        </Tooltip>
    );
}

function nextMoveButton(action: () => void) {
    return (
        <Tooltip title='Next Move'>
            <IconButton onClick={action} color="primary">
                <Redo fontSize='large' />
            </IconButton>
        </Tooltip>
    );
}

function resetBoardButton(action: () => void) {
    return (
        <Tooltip title='Reset Board'>
            <IconButton onClick={action} color="primary">
                <Replay fontSize='large' />
            </IconButton>
        </Tooltip>
    );
}

function importFromPGNButton(action: () => void) {
    return (
        <Tooltip title='Import PGN'>
            <IconButton onClick={action} color="primary">
                <FileUpload fontSize='large' />
            </IconButton>
        </Tooltip>
    );
}

function flipBoard(action: () => void) {
    return (
        <Tooltip title='Flip Chessboard'>
            <IconButton onClick={action} color="primary">
                <Cached fontSize='large' />
            </IconButton>
        </Tooltip>
    );
}

type ImportFromPGNPopupProps = {
    isOpen: boolean;
    PGNImportCallback: (pgn: string) => void;
    closeCallback: () => void;
}

function ImportFromPGNPopup(props: ImportFromPGNPopupProps) {
    const [hasValidationFailed, setValidationHasFailed] = useState(false);
    const [helerText, setHelperText] = useState('')
    const PGNRef = useRef<any>()

    const handleImport = () => {
        const pgn = PGNRef?.current.value ?? ''
        const game = new Chess();
        try {
            game.loadPgn(pgn);
        } catch {
            setValidationHasFailed(true);
            setHelperText('Invalid PGN entry')
        }
        props.PGNImportCallback(pgn);
        handleClose();
    }
    const handleClose = () => {
        setHelperText('');
        setValidationHasFailed(false);
        props.closeCallback();
    }
    return (
        <Dialog
            open={props.isOpen}
            onClose={handleClose}
        >
            <DialogTitle>
                {"Import PGN"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Paste single game PGN string in the box below
                </DialogContentText>
                <TextField
                    autoFocus
                    multiline
                    label="PGN String"
                    variant="outlined"
                    inputRef={PGNRef}
                    rows={5}
                    sx={{ width: '100%', mt: 4 }}
                    helperText={helerText}
                    error={hasValidationFailed}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleImport}>
                    Import
                </Button>
                <Button onClick={handleClose}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}