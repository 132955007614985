import RightPaneHeader from "./components/header/RightPaneHeader";
import RightPaneContainer from "./components/table/container/RightPaneContainer";
import { MaterialModel, MaterialModelType } from "../../MaterialModel";
import { useState, useEffect, useRef } from 'react';
import { Box, Button, LinearProgress } from "@mui/material";
import IconCardComponent from "../../../common/icon_card/IconCardComponent";
import ContributionFormComponent from "../../../home/Contribute/Components/ContributionFormComponent";
import { OpeningModel } from "../../MaterialModel";
import YoutubeRenderComponent from "./components/video/YoutubeRenderComponent";

type RightPaneProps = {
  fen: string;
}

export default function RightPane(props: RightPaneProps) {
  const [materials, setMaterials] = useState<{ [key: string]: Array<MaterialModel> }>({});
  const [isEmpty, setIsEmpty] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [embededVideoModel, setEmbededVideoModel] = useState<MaterialModel | null>(null);
  const videoRef = useRef<null | HTMLDivElement>(null);

  let loader = null;
  if (isLoading === true) {
    loader = (
      <Box color='primary' sx={{ width: '100%', my: 2, height: 3, borderRadius: 1 }}>
        <LinearProgress />
      </Box>
    );
  }

  let embededVideo = null;
  if (embededVideoModel !== null) {
    const onClose = () => {
      setEmbededVideoModel(null);
    }
    embededVideo = (
      <Box ref={videoRef} display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <Box sx={{ ml: 'auto', mr: '2' }}>
          <Button onClick={onClose}>close video</Button>
        </Box>
        <YoutubeRenderComponent model={embededVideoModel} />
      </Box>
    );
  }

  useEffect(
    function () {
      if (videoRef.current !== null) {
        videoRef.current.scrollIntoView({ block: 'center', behavior: 'smooth' });
      }
    }, [embededVideoModel]
  )

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const fetch = async () => {
      const res = await fetchLibrary(props.fen, signal);
      setLoading(false);
      const parsed = parseLibrary(res);
      setMaterials(parsed[0]);

      let total_count = 0;
      for (let key in parsed[0]) {
        total_count += parsed[0][key].length;
      }
      if (total_count === 0) {
        setIsEmpty(true);
      } else {
        setIsEmpty(false);
      }
    }
    setLoading(true);
    fetch();
    return () => {
      // Abort the request when the component unmounts or when a dependency changes
      controller.abort();
    };
  }, [props.fen]);

  const modelSelected = (model: MaterialModel) => {
    setEmbededVideoModel(model);
  }

  return (
    <Box m={2} className="Box-Right-Pane">
      {
        isEmpty ? (
          <div>
            <IconCardComponent
              iconType="Question"
              title="No materials for this position yet"
              text="We have not found any learning materials for this position. If you know of any, consider contributing by using the form below." />
            <Box mt={2}>
              <ContributionFormComponent />
            </Box>
          </div>
        ) : (
          <div>
            {embededVideo}
            <RightPaneHeader />
            {loader}
            <RightPaneContainer
              title="Videos"
              key="Videos"
              materials={materials[MaterialModelType.Video]}
              should_filter_by_origin={true}
              is_loading={isLoading}
              origin_filter_title="Selected channel:"
              modelSelected={modelSelected} 
              adKey="2f11be5fa19226c4b8463caf224b8660"/>
            <RightPaneContainer
              title="Courses"
              key="Courses"
              is_loading={isLoading}
              materials={materials[MaterialModelType.Course]}
              should_filter_by_origin={false} 
              adKey="95f6d5bdac3bd456f65d1c54dcc12177"/>
            <RightPaneContainer
              title="Books"
              key="Books"
              is_loading={isLoading}
              materials={materials[MaterialModelType.Book]}
              should_filter_by_origin={false}
              adKey="bcfed4b2e961d8f96b084f7f9e43eb6e" />
            <RightPaneContainer
              title="Model Games"
              key="Model Games"
              is_loading={isLoading}
              materials={materials[MaterialModelType.NotableGame]}
              should_filter_by_origin={false}
              adKey="a4cdb4625fcd4d50ca33f64f6c65dd2e" />
            <RightPaneContainer
              title="Puzzles"
              key="Puzzles"
              is_loading={isLoading}
              materials={materials[MaterialModelType.Puzzle]}
              should_filter_by_origin={false} 
              adKey="81701a93658aba6c0c15304140bab885"/>
            <RightPaneContainer
              title="Studies"
              key="Studies"
              is_loading={isLoading}
              materials={materials[MaterialModelType.Study]}
              should_filter_by_origin={false} 
              adKey="1a370297f53fee621d9616167da06ff9"/>
            <RightPaneContainer
              title="Anything Else"
              key="Anything Else"
              is_loading={isLoading}
              materials={materials[MaterialModelType.AnythingElse]}
              should_filter_by_origin={false} 
              adKey=""/>
          </div>
        )}
    </Box>
  );
}

export function parseLibrary(data: any): [{ [key: string]: Array<MaterialModel> }, OpeningModel?] {
  const result: { [key: string]: Array<MaterialModel> } = {};
  if (data.length === 0) {
    return [result, undefined];
  }
  const dict = JSON.parse(data);
  for (const type in dict) {
    var material_type = MaterialModelType.AnythingElse
    if (type === 'video') {
      material_type = MaterialModelType.Video;
    }
    if (type === 'book') {
      material_type = MaterialModelType.Book;
    }
    if (type === 'notable_game') {
      material_type = MaterialModelType.NotableGame;
    }
    if (type === 'puzzle') {
      material_type = MaterialModelType.Puzzle;
    }
    if (type === 'study') {
      material_type = MaterialModelType.Study;
    }
    if (type === 'course') {
      material_type = MaterialModelType.Course;
    }
    let material_data_array = dict[type];
    var material_array = [];
    for (let index in material_data_array) {
      let material_data = material_data_array[index];
      const material = new MaterialModel(
        material_data['url'] as string,
        material_type,
        material_data['title'] as string,
        material_data['thumbnailURL'] as string,
        material_data['origin'] as string,
        material_data['timestamp'] as string,
      );
      material_array.push(material);
    }
    result[material_type] = material_array;
  }

  if ("opening_name" in dict) {
    let model = new OpeningModel(dict["opening_name"])

    if ("opening_variation" in dict) {
      model.variation = dict["opening_variation"]
    }

    return [result, model]
  }

  return [result, undefined]
}

export async function fetchLibrary(fen: string, signal: AbortSignal) {
  if (fen !== undefined && fen !== null && fen.length > 0) {
    const url = process.env.REACT_APP_API_URI + "/library/?fen=" + fen;
    try {
      let p = await fetch(url, { signal });
      if (p.status < 400) {
        let json = await p.json();
        return json;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  } else {
    return [];
  }
}
