import { OpeningTreeNode } from '../../OpeningTreeModel';
import OpeningTreeHeader from './components/header/OpeningTreeHeader';
import OpeningTreeTable from './components/table/OpeningTreeTable';
import { OpeningTreeSortingType } from './components/header/sorting/OpeningTreeSortingModel';
import { Box } from '@mui/material';
import IconCardComponent from '../../../common/icon_card/IconCardComponent';
import DepthTreeTable from './components/depth_tree/DepthTreeTable';

type MiddlePaneProps = {
    nodes: OpeningTreeNode[];

    currentOpening?: string;

    moveCallback: (san: string) => void;
    movesDidChange: (moves: string) => void;
    currentMoves?: string;

    currentSortType: OpeningTreeSortingType
    sortingTypeDidChange: (newType: OpeningTreeSortingType) => void;

    whiteOpeningSelected?: () => void;
    blackOpeningSelected?: () => void;

    treeDepth: number;
    treeDepthDidChange: (treeDepth: number) => void;
}

export default function MiddlePane(props: MiddlePaneProps) {
    const openingTreeTable = props.treeDepth === 1 ? (
        <OpeningTreeTable
            nodes={props.nodes}
            moveCallback={props.moveCallback}
        />
    ) : null;
    const depthTreeTable = props.treeDepth !== 1 ? (
        <DepthTreeTable
            nodes={props.nodes}
            moveCallback={props.movesDidChange}
        />
    ) : null;

    return (
        <Box m={2}>
            <OpeningTreeHeader
                movesCount={props.nodes.length}
                currentMoves={props.currentMoves}
                movesDidChange={props.movesDidChange}
                currentSortType={props.currentSortType}
                sortingTypeDidChange={props.sortingTypeDidChange}
                currentOpening={props.currentOpening}
                whiteOpeningSelected={props.whiteOpeningSelected}
                blackOpeningSelected={props.blackOpeningSelected}
                currentTreeDepth={props.treeDepth}
                treeDepthDidChange={props.treeDepthDidChange}
            />
            {
                props.nodes.length > 0 ? (
                    props.treeDepth === 1 ? openingTreeTable : depthTreeTable
                ) : (
                    <IconCardComponent
                        iconType='Question'
                        title='No games'
                        text="Doesn't see.m like you have had any games in this position. Don't worry you can continue browsing learning materials or alternatively you can expand your search." />
                )
            }
        </Box>
    );
}