import { Box, Typography } from "@mui/material";

export default function BlogBody(data: any) {
    const path = "/blog/images/" + data["data"]["large_image"];

    const sections = data["data"]["body"]["sections"]
    let section_elements = null;
    if (sections !== null && sections !== undefined) {
        section_elements = sections.map((section: any) => sectionElementFromData(section))
    }

    return (
        <Box
            component="section"
            sx={{
                bgcolor: 'background.paper',
                borderRadius: 3,
                p: 4,
                m: {
                    xs: 3,
                    sm: 4
                },
                paddingBottom: {
                    xs: 2,
                    sm: 2
                },
                overflow: 'clip'
            }}>
            <Box borderRadius={2} overflow={'clip'}>
                <center>
                    <img src={path} alt="" height={'auto'} width={'100%'} />
                </center>
            </Box>
            <Typography
                variant='h4'
                style={{ fontWeight: 700 }}
                letterSpacing={1.5}
                align='left'
                sx={{ paddingBottom: 2, mt: 1 }}>
                {data["data"]["title"]}
            </Typography>
            <Typography
                variant='body1'
                color={'text.secondary'}
                align='left'>
                {data["data"]["body"]["paragraph"]}
            </Typography>
            {section_elements}
        </Box>
    );
}

function sectionElementFromData(data: any) {
    const path = "/blog/images/" + data["section_image"];

    return (
        <Box my={2}>
            <Box borderRadius={2} overflow={'clip'}>
                <center>
                    <img src={path} alt="" height={'300'} width={'auto'} />
                </center>
            </Box>
            <Typography variant="h5">
                {data["section_title"]}
            </Typography>
            <Typography variant="body2" color={'text.secondary'}>
                {data["section_body"]}
            </Typography>
        </Box>
    );
}