import IconCardComponent from "../../../../../common/icon_card/IconCardComponent";
import { Box } from "@mui/material";

export default function RightPaneHeader() {
    return (
        <Box mb={2}>
            <IconCardComponent
            iconType="School"
            title="Learning materials"
            text="Below you can find all of our learning materials for this position grouped by type. Simply expand any section and dive into learning." />
        </Box>
    );
}