import { Grid } from "@mui/material";
import IconCardComponent from "../../../common/icon_card/IconCardComponent";

export default function SearchIconsComponent() {
    return (
        <Grid container spacing={2} sx={{ padding: {xs:3, sm:8}, paddingTop: {xs:0, sm:0}}}>
            <Grid item sm={12} md={6}>
                <IconCardComponent
                    iconType="Book"
                    text="Embark on a chess adventure by discovering books that dive into the very positions you encounter in your games."
                    title="Books" />
            </Grid>
            <Grid item sm={12} md={6}>
                <IconCardComponent
                    iconType="Video"
                    text="Crafted by your favourite content creators, find videos featuring positions you encounter in your games."
                    title="Videos" />
            </Grid>
            <Grid item sm={12} md={6}>
                <IconCardComponent
                    iconType="School"
                    text="Explore a wide range of free and premium courses for your openings. Discovering relevant courses specifically for you has never been easier."
                    title="Courses and Studies" />
            </Grid>
            <Grid item sm={12} md={6}>
                <IconCardComponent
                    iconType="More"
                    text="Many more learning materials like puzzles, notable games and articles. All hand picked for you and tailored to your playing style."
                    title="And so much more" />
            </Grid>
        </Grid>
    );
}

