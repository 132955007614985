import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

type ValidationFailedDialogProps = {
    open: boolean;
    closeCallback: () => void;
}

export default function ValidationFailedDialog(props: ValidationFailedDialogProps) {
  const handleClose = () => {
    props.closeCallback();
  };

  return (
      <Dialog
        open={props.open}
        onClose={handleClose}
      >
        <DialogTitle>
          {"Invalid input"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter valid email address and provide feedback.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
  );
}