import { Box, Typography, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useState, useRef } from 'react';
import SubmissionSuccessfulDialog from "./Popups/SubmissionSuccessful";
import ValidationFailedDialog from "./Popups/ValidationFailedPopup";

export default function FeedbackComponent() {
    const [loading, setLoading] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [validationFailed, setValidationFailed] = useState(false);
    const emailRef = useRef<any>()
    const feedbackRef = useRef<any>()

    const validateSubmission = (): Boolean =>  {
        const email = emailRef?.current.value.trim();
        let regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i;
        const isValidEmail = regex.test(email.replace(/\s/g, ''));
        const isValidFeedback = feedbackRef?.current.value.length > 0;
        return isValidEmail && isValidFeedback;
    }

    const showValidationPopup = () => {
        setValidationFailed(true);
        setLoading(false);
    }

    const handleSubmit = () => {
        setLoading(true);

        if(validateSubmission() === false) {
            showValidationPopup();
            return;
        }

        const email = emailRef?.current.value;
        const feedback = feedbackRef?.current.value;
        var url = process.env.REACT_APP_API_URI + "/feedback_sub/?email=" + email + "&feedback=" + feedback;
        fetch(url)
            .then(res => res.json())
            .then(
                (result) => {
                    setLoading(false);
                    setAlertOpen(true);
                },
                (error) => {
                    setLoading(false);
                    setAlertOpen(true);
                }
            )
    }

    const handleCloseAlert = () => {
        setAlertOpen(false);
    }

    const handleCloseValidation = () => {
        setValidationFailed(false);
    }

    return (
        <Box component="section"
            sx={{
                p: 2,
                paddingLeft: 3,
                flexDirection: 'Column',
                display: 'flex',
                alignItems: 'center',
                bgcolor: 'background.paper',
                borderRadius: 3,
                mb: 4
            }}>
            <Typography variant="h6" align='left'>
                For any questions or suggestions
            </Typography>
            <Typography
                variant='body1'
                color={'text.secondary'}
                align='left'>
                Feel free to submit any feedback by using this form below:
            </Typography>
            <TextField
                required
                label="Contact email"
                variant="outlined"
                inputMode='email'
                inputRef={emailRef}
                sx={{ m: 2, minWidth: {
                    sm: 325,
                    md: 475,
                } }} />
            <TextField
                required
                multiline
                label="Your feedback"
                variant="outlined"
                inputRef={feedbackRef}
                rows={4}
                sx={{ marginBottom: 2, mx: 2, minWidth: {
                    sm: 325,
                    md: 475,
                } }} />
            <LoadingButton
                variant="contained"
                loading={loading}
                onClick={handleSubmit}
                sx={{ maxWidth: 120 }}>
                <span>Submit</span>
            </LoadingButton>
            <SubmissionSuccessfulDialog open={alertOpen} closeCallback={handleCloseAlert} />
            <ValidationFailedDialog open={validationFailed} closeCallback={handleCloseValidation}/>
        </Box>
    );
}

