import { MaterialModel, MaterialModelType } from "../../../../../../MaterialModel";
import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import lichess_logo from '../../../../../../../lichess_logo.png'

type RightPaneContainerProps = {
    model: MaterialModel
}

export function TitleAndThumbRender(props: RightPaneContainerProps) {
    let url = props.model.url;
    let origin = null;
    if (props.model.origin !== null && props.model.origin !== undefined && props.model.origin.length > 0) {
        origin = (
            <Typography variant="body2" component="div" color={'text.secondary'} fontSize={13}>
                {'by ' + props.model.origin}
            </Typography>
        );
    }
    return (
        <Card sx={{ maxHeight: 400, borderRadius: 3, backgroundColor: 'background.default' }}>
            <CardActionArea href={url} target="_blank">
                <CardMedia
                    component="img"
                    height="150"
                    image={props.model.thumbnailURL}
                />
                <CardContent>
                    <Typography variant="body2" component="div">
                        {props.model.title}
                    </Typography>
                    {origin}
                </CardContent>
            </CardActionArea>
        </Card>
    );
}

type MaterialRenderPropsWithCallback = {
    model: MaterialModel;
    modelSelected?: (model: MaterialModel) => void;
}

export function VideoMaterialRender(props: MaterialRenderPropsWithCallback) {
    let url = props.model.url;
    if (props.model.timestamp !== null && props.model.timestamp !== undefined && props.model.timestamp.length > 0) {
        url += '&t=' + props.model.timestamp + 's';
    }
    let origin = null;
    if (props.model.origin !== null && props.model.origin !== undefined && props.model.origin.length > 0) {
        origin = (
            <Typography variant="body2" component="div" color={'text.secondary'} fontSize={13}>
                {'by ' + props.model.origin}
            </Typography>
        );
    }

    const onClick = () => {
        if (props.modelSelected !== undefined) {
            props.modelSelected(props.model);
        }
    }

    return (
        <Card sx={{ maxHeight: 400, borderRadius: 3, backgroundColor: 'background.default' }}>
            <CardActionArea onClick={onClick}>
                <CardMedia
                    component="img"
                    height="150"
                    image={props.model.thumbnailURL}
                />
                <CardContent>
                    <Typography variant="body2" component="div">
                        {props.model.title}
                    </Typography>
                    {origin}
                </CardContent>
            </CardActionArea>
        </Card>
    );
}

export function BookMaterialRender(props: RightPaneContainerProps) {
    return (
        <TitleAndThumbRender model={props.model} />
    );
}

export function NotableGameMaterialRender(props: RightPaneContainerProps) {
    var components = props.model.title?.split('|');

    var whitePlayer = '';
    var blackPlayer = '';
    var location = '';
    var year = '';
    if (components !== undefined) {
        const players = components[0].split('-');
        whitePlayer = players[0].trim();
        blackPlayer = players[1].trim();
        var metadata = components[1].trim();
        year = metadata.slice(-4);
        location = metadata.slice(0, -4);
    }

    return (
        <Card sx={{
            maxHeight: 300,
            borderRadius: 3,
            backgroundColor: 'background.default',
        }}>
            <CardActionArea href={props.model.url} target="_blank">
                <CardContent sx={{
                    alignItems: 'center',
                }}>
                    <Typography variant="body1" color={'text.secondary'} fontSize={13}>
                        White:
                    </Typography>
                    <Typography variant="body1" color={'text.primary'}>
                        {whitePlayer}
                    </Typography>
                    <Typography variant="body1" color={'text.secondary'} fontSize={13}>
                        Black:
                    </Typography>
                    <Typography variant="body1" color={'text.primary'}>
                        {blackPlayer}
                    </Typography>
                    <Typography variant="body1" color={'text.secondary'} fontSize={13}>
                        {year}
                    </Typography>
                    <Typography variant="body1" color={'text.secondary'} fontSize={13}>
                        {location}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}

export function PuzzleMaterialRender(props: RightPaneContainerProps) {
    return (
        <Card sx={{ maxHeight: 300, borderRadius: 3, backgroundColor: 'background.default' }}>
            <CardActionArea href={props.model.url} target="_blank">
                <CardMedia
                    component="img"
                    height="180"
                    image={lichess_logo}
                />
            </CardActionArea>
        </Card>
    );
}

export function StudyMaterialRender(props: RightPaneContainerProps) {
    return (
        <Card sx={{ maxHeight: 300, borderRadius: 3, backgroundColor: 'background.default' }}>
            <CardActionArea href={props.model.url} target="_blank">
                <CardMedia
                    component="img"
                    height="90"
                    image={lichess_logo}
                />
                <CardContent>
                    <Typography variant="body2" component="div">
                        {props.model.title}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}

export function AnythingElseMaterialRender(props: RightPaneContainerProps) {
    return (
        <div>
            This is else box
        </div>
    );
}

export function CourseMaterialRender(props: RightPaneContainerProps) {
    return (
        <TitleAndThumbRender model={props.model} />
    );
}

export default function MaterialModelRender(props: MaterialRenderPropsWithCallback) {
    const type = props.model.type;
    var render;
    if (type === MaterialModelType.Video) {
        render = <VideoMaterialRender model={props.model} modelSelected={props.modelSelected}/>
    }
    if (type === MaterialModelType.Book) {
        render = <BookMaterialRender model={props.model} />
    }
    if (type === MaterialModelType.NotableGame) {
        render = <NotableGameMaterialRender model={props.model} />
    }
    if (type === MaterialModelType.Puzzle) {
        render = <PuzzleMaterialRender model={props.model} />
    }
    if (type === MaterialModelType.Study) {
        render = <StudyMaterialRender model={props.model} />
    }
    if (type === MaterialModelType.Course) {
        render = <CourseMaterialRender model={props.model} />
    }
    if (type === MaterialModelType.AnythingElse) {
        render = <AnythingElseMaterialRender model={props.model} />
    }
    return (
        <div>
            {render}
        </div>
    );
}
