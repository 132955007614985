import { Box, Typography } from "@mui/material";

type BlogTileProps = {
    title: string;
    body: string;
    img: string;
    date: string;
}
export default function BlogTile(props: BlogTileProps) {
    const path = "/blog/images/" + props.img;
    return (
        <Box
            sx={{
                p: 4,
                pb: 2,
                paddingLeft: 3,
                flexDirection: 'row',
                display: 'flex',
                alignItems: 'top',
                bgcolor: 'background.paper',
                borderRadius: 3,
            }}>
            <img src={path} alt="" width={100} height={100}/>
            <Box
                sx={{
                    mx: 2,
                    flexDirection: 'column',
                    display: 'flex',
                    alignItems: 'left',
                    bgcolor: 'background.paper',
                }}>
                <Typography variant="h5" fontWeight={700}>
                    {props.title}
                </Typography>
                <Typography variant="body2" color={"text.secondary"}>
                    {props.body}
                </Typography>
                <Typography sx={{ml: 'auto'}} variant="body2" fontSize={12}> 
                    {props.date}
                </Typography>
            </Box>
        </Box>
    );
}