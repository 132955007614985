import { Box, Button, Typography } from "@mui/material";
import blogPosts from "../../Posts/Posts";
import { useNavigate } from "react-router-dom";

export default function BlogNavigation() {
    const navigate = useNavigate();
    const callback = (blog: any) => {
        navigate('/blog_post',
            {
                state: {
                    data: blog,
                }
            });
    }
    const buttons = blogPosts.map((blog) => blogButton(blog, callback));
    return (
        <Box display={{ xs: 'none', md: 'block' }}
            sx={{
                mt: 4,
                mr: 4,
                mb: 2,
                p: 2,
                bgcolor: 'background.paper',
                borderRadius: 3,
            }}>
            <Typography variant="h5" m={2} fontWeight={700}>
                Recent posts
            </Typography>
            <Box display={'flex'} flexDirection={'column'}>
                {buttons}
            </Box>
        </Box>
    );
}

function blogButton(blog: any, callback: (blog: any) => void) {
    const onClick = () => {
        callback(blog);
    }
    return (
        <Box display={'flex'} flexDirection={'column'} m={1}>
            <Button
                onClick={onClick}
                style={{
                    textTransform: 'none',
                    justifyContent: "flex-start"
                }}
                variant="text">
                {blog["title"]}
            </Button>
            <Typography sx={{ ml: 'auto', mr: 1 }} variant="body2" fontSize={12} color={"text.secondary"}>
                {blog["date"]}
            </Typography>
        </Box>
    );
}