import { useEffect, useState, useMemo, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Chess } from "chess.js";
import './OpeningTree.css';
import './OpeningTreeModel.ts';
import { Profile } from './OpeningTreeModel';
import { Box, Grid } from '@mui/material';
import MiddlePane from './components/middle_pane/MiddlePane';
import RightPane from './components/right_pane/RightPane';
import { Square } from 'react-chessboard/dist/chessboard/types';
import { OpeningTreeSortingType, OpeningTreeSortingModel } from './components/middle_pane/components/header/sorting/OpeningTreeSortingModel';
import LogoComponent from '../common/logo_card/LogoComponent';
import ChessboardControlsComponent from './components/chessboard/ChessboardControlsComponent';
import ChessboardComponent from './components/chessboard/ChessboardComponent';

function OpeningTree() {
  // game object
  const [game, setGame] = useState(new Chess());

  // loading opening tree moves and profile info
  const { state } = useLocation();
  const profile = useMemo(() => {
    return new Profile(state.data);
  }, [state.data])

  // starting colour is white, fetch nodes for white player
  const whiteNodes = profile.whiteOpeningTree.nodesFromFEN(game.fen());
  const [nodes, setNodes] = useState(whiteNodes);

  // need for retracing variations
  const [fen, setFen] = useState('')
  const [largestPGN, setLargestPGN] = useState('')

  // filtering options
  const [currentColor, setCurrentColor] = useState(true);
  const [sortingType, setSortingType] = useState(OpeningTreeSortingType.Count);
  const [treeDepth, setTreeDepth] = useState(1);

  // show current opening name
  const [openingString, setOpeningString] = useState('')

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const keyDownEvent = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.code === "ArrowRight") {
      forwardVariation();
    }
    if (event.code === "ArrowLeft") {
      retraceVariation();
    }
  };

  const getSortedNodes = useCallback((fen: string, color: boolean) => {
    const opening_tree = color ? profile.whiteOpeningTree : profile.blackOpeningTree;
    const newNodes = treeDepth === 1 ? opening_tree.nodesFromFEN(fen) : opening_tree.nodesForDepth(treeDepth);
    const sorting_model = new OpeningTreeSortingModel(newNodes, sortingType);
    return sorting_model.getSortedNodes();
  }, [sortingType, profile.whiteOpeningTree, profile.blackOpeningTree, treeDepth]);

  // update state of the board when something changes
  const updateChessboard = useCallback(() => {
    const sorted_nodes = getSortedNodes(game.fen(), currentColor);
    setNodes([...sorted_nodes]);

    // set largest pgn for forward arrowkey navigation
    if (game.pgn().length > largestPGN.length) {
      setLargestPGN(game.pgn())
    } else {
      if (largestPGN.startsWith(game.pgn()) === false) {
        setLargestPGN(game.pgn())
      }
    }

    // set opening name string
    if (game.pgn().length === 0) {
      setOpeningString('')
    } else {
      const fen = game.fen().split(" ", 3).join(" ")
      const new_opening_name = localStorage.getItem(fen);
      if (new_opening_name !== null) {
        setOpeningString(new_opening_name);
      }
    }

    setFen(game.fen());

    // preserve current state
    window.sessionStorage.setItem("game", game.pgn());
  }, [currentColor, game, largestPGN, getSortedNodes]);

  // when game object changes, we need to update most of the things to reflect that
  useEffect(() => {
    const pgn = window.sessionStorage.getItem("game");
    if (pgn !== null) {
      game.loadPgn(pgn);
    }
    updateChessboard();
  }, [game, updateChessboard]);

  // when sorting type changes -> update nodes
  useEffect(() => {
    updateChessboard();
  }, [sortingType, updateChessboard]);

  const whiteOpeningTreeSelected = () => {
    setTreeDepth(1);
    setCurrentColor(true);
    // need to reset game/pgn/fen/etc
    const new_game = new Chess();
    setGame(new_game);
  }

  const blackOpeningTreeSelected = () => {
    setTreeDepth(1);
    setCurrentColor(false);
    // need to reset game/pgn/fen/etc
    const new_game = new Chess();
    setGame(new_game);
  }

  const moveCallback = (san: string) => {
    setTreeDepth(1);
    game.move(san);
    updateChessboard();
  }

  const retraceVariation = () => {
    if (treeDepth !== 1) {
      return;
    }
    game.undo();
    updateChessboard();
  }

  const forwardVariation = () => {
    if (treeDepth !== 1) {
      return;
    }
    const tempGame = new Chess();
    tempGame.loadPgn(game.pgn());
    const sl = tempGame.history().length
    tempGame.loadPgn(largestPGN);
    var ll = tempGame.history().length
    while (ll !== sl + 1 && ll > sl) {
      tempGame.undo();
      ll -= 1;
    }
    game.loadPgn(tempGame.pgn());
    updateChessboard();
  }

  const onPieceDrop = (sourceSquare: Square, targetSquare: Square) => {
    const move = game.move(
      {
        from: sourceSquare,
        to: targetSquare,
        promotion: "q"
      }
    )
    if (move === null) {
      return false;
    }
    setTreeDepth(1);
    updateChessboard();
    return true;
  }

  const movesDidChange = (pgn: string) => {
    setTreeDepth(1);
    game.loadPgn(pgn);
    updateChessboard();
  }

  const resetBoard = () => {
    game.loadPgn('');
    setTreeDepth(1);
    updateChessboard();
  }

  const flipBoard = () => {
    setTreeDepth(1);
    setCurrentColor(!currentColor);
    const new_game = new Chess();
    setGame(new_game);
  }

  const analyseOnChessCom = () => {
    const url = "https://www.chess.com/analysis?tab=analysis&pgn=" + game.pgn().split(' ').join('+') + '&move=' + game.moves().length;
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const analyseOnLichessOrg = () => {
    const url = "https://lichess.org/analysis/pgn/" + game.pgn().split(' ').join('_')
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const treeDepthDidChange = (new_depth: number) => {
    resetBoard();
    setTreeDepth(new_depth);
    const opening_tree = currentColor ? profile.whiteOpeningTree : profile.blackOpeningTree;
    const newNodes = opening_tree.nodesForDepth(new_depth);
    const sorting_model = new OpeningTreeSortingModel(newNodes, sortingType);
    setNodes([...sorting_model.getSortedNodes()]);
  }

  return (
    <header className="Container-opening-tree" onKeyDown={keyDownEvent} tabIndex={0}>
      <Box mt={2} style={{ userSelect: "element" }}>
        <LogoComponent />
      </Box>
      <Grid container spacing={3}>
        {/* Chess board renderer. TODO: move to a separate component */}
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Box m={2} borderRadius={3} overflow={'clip'}>
            <ChessboardComponent
              fen={fen}
              onDrop={onPieceDrop}
              color={currentColor}
            />
          </Box>
          <ChessboardControlsComponent
            previousMove={retraceVariation}
            nextMove={forwardVariation}
            resetBoard={resetBoard}
            setPgn={movesDidChange}
            flipBoard={flipBoard}
            analyseOnChessCom={analyseOnChessCom}
            analyseOnLichessOrg={analyseOnLichessOrg} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <MiddlePane
            nodes={nodes}
            currentOpening={openingString}
            currentMoves={(game.pgn() === '') ? undefined : game.pgn()}
            movesDidChange={movesDidChange}
            moveCallback={moveCallback}
            currentSortType={sortingType}
            sortingTypeDidChange={setSortingType}
            whiteOpeningSelected={whiteOpeningTreeSelected}
            blackOpeningSelected={blackOpeningTreeSelected}
            treeDepth={treeDepth}
            treeDepthDidChange={treeDepthDidChange}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <RightPane
            fen={fen}
          />
        </Grid>
      </Grid>
    </header>
  );
}

export default OpeningTree;
