import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

type SubmissionSuccessfulDialogProps = {
    open: boolean;
    closeCallback: () => void;
}

export default function SubmissionSuccessfulDialog(props: SubmissionSuccessfulDialogProps) {
  const handleClose = () => {
    props.closeCallback();
  };

  return (
      <Dialog
        open={props.open}
        onClose={handleClose}
      >
        <DialogTitle>
          {"Thank you for your feedback"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            We will review your submission shortly.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
  );
}