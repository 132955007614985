import RightPaneMaterialGrid from '../material/RightPaneMaterialGrid';
import { MaterialModel } from '../../../../../MaterialModel';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, Select, SelectChangeEvent, MenuItem, CircularProgress, Button } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { useEffect, useState } from 'react';

// Title of the section of the table + materials grid

const materials_count = 12;

type RightPaneContainerProps = {
    adKey: string;
    title: string;
    materials: Array<MaterialModel> | undefined;
    should_filter_by_origin: Boolean;
    is_loading: Boolean;
    origin_filter_title?: string;
    modelSelected?: (model: MaterialModel) => void;
}

export default function RightPaneContainer(props: RightPaneContainerProps) {
    const [selectedOrigin, setSelectedOrigin] = useState('All');
    const [showLimit, setShowLimit] = useState(materials_count);

    useEffect(() => {
        setShowLimit(materials_count);
    }, [props.materials]);

    const increase_limit = () => {
        setShowLimit(showLimit + materials_count);
    }

    if (props.materials === undefined) {
        return <div></div>;
    }
    if (props.materials.length === 0) {
        return <div></div>;
    }

    let materials = props.materials

    let filterComponent = null;
    if (props.should_filter_by_origin === true) {
        let origins = new Set<string>();
        props.materials.map(function (val) {
            if (val.origin !== null && val.origin !== undefined) {
                origins.add(val.origin)
            }
            return null;
        })
        const title = props.origin_filter_title ?? "";
        var values = Array.from(origins.values());
        values.splice(0, 0, 'All');

        const filterChanged = (origin: string) => {
            setShowLimit(materials_count);
            setSelectedOrigin(origin);
        }

        filterComponent = (originFilter(values, selectedOrigin, title, filterChanged));

        if (selectedOrigin !== 'All') {
            materials = materials.filter((material) => material.origin === selectedOrigin);
        }
    }

    let loadMoreButton = null;
    if (showLimit < materials.length) {
        loadMoreButton = (
            <Button sx={{
                mt: 2,
                ml: 2
            }}
                onClick={increase_limit}>
                load more
            </Button>
        );
    }

    var icon = null;
    if (props.is_loading === true) {
        icon = (<CircularProgress color="primary" size={"1rem"} sx={{ marginRight: 0.5 }} />)
    } else {
        icon = (<ExpandMore color='primary' sx={{ marginRight: 0.5 }} />);
    }

    return (
        <Box component="section"
            sx={{
                flexDirection: 'column',
                display: 'flex',
                alignItems: 'left',
                bgcolor: 'background.paper',
                borderRadius: 3,
                mb: 2,
                overflow: 'clip'
            }}>
            <Accordion>
                <AccordionSummary expandIcon={icon}>
                    <Typography variant='h6' sx={{ marginLeft: 2 }}>
                        {props.title}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {filterComponent}
                    <RightPaneMaterialGrid adKey={props.adKey} materials={materials.slice(0, showLimit)} modelSelected={props.modelSelected} />
                    {loadMoreButton}
                </AccordionDetails>
            </Accordion>
        </Box>
    );
}

function originFilter(
    values: string[],
    currentSortType: string,
    title: string,
    originFilterChanged: (newType: string) => void
) {

    const handleChange = (event: SelectChangeEvent) => {
        const new_type = event.target.value as string
        originFilterChanged(new_type)
    }

    return (
        <Box>
            <Typography>{title}</Typography>
            <Select
                value={currentSortType}
                onChange={handleChange}
                variant="outlined"
                sx={{
                    mb: 2,
                    width: "auto",
                    color: "white",
                    '.MuiOutlinedInput-notchedOutline': {
                        borderColor: 'primary.main',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'primary.main',
                    },
                    '.MuiSvgIcon-root ': {
                        fill: "white !important",
                    }
                }}
            >
                {
                    values.map(
                        type => { return (<MenuItem key={type} value={type}>{type}</MenuItem>); }
                    )
                }

            </Select>
        </Box>
    );
}
