import { SQUARES } from 'chess.js'

export class Move {
    fromSquare: string;
    toSquare: string;
    promotion?: string;
    dropPiece?: string;
    san: string;

    constructor(dictionary: any, san: string) {
        this.fromSquare = Move.squareFromNumber(dictionary["from_square"] as number);
        this.toSquare = Move.squareFromNumber(dictionary["to_square"] as number);
        this.san = san;
        // TODO: promotion and drop piece
    }

    static squareFromNumber(number: number): string {
        let pythonSquares = Array(64);
        for (let i = 0; i < 8; i++) {
            for (let j = 0; j < 8; j++) {
                pythonSquares[i * 8 + j] = 56 - (i * 8)  + j // converting squares from python to js move array
            }
        }
        return SQUARES[pythonSquares[number]];
    }
}

export class Profile {
    whiteOpeningTree: OpeningTreeModel;
    blackOpeningTree: OpeningTreeModel;
    name: string;

    constructor(dictionary: any) {
        this.name = dictionary["name"] as string;
        this.whiteOpeningTree = new OpeningTreeModel(dictionary["whiteOpeningTree"]);
        this.blackOpeningTree = new OpeningTreeModel(dictionary["blackOpeningTree"]);
    }
}

export class OpeningTreeModel {
    name: string;
    colour: string;
    count: number;
    map: { [key: string]: Array<OpeningTreeNode> } = {};
    depth_map: {[key: number]: Array<OpeningTreeNode>} = {};

    constructor(dictionary: any) {
        this.name = dictionary["name"] as string;
        this.colour = dictionary["colour"] as string;
        this.count = dictionary["count"] as number;
        const map = dictionary["map"];
        for (const fen_key in map) {
            const san_dict = map[fen_key];
            var result_fen_array = this.map[fen_key] || [];
            for (const san in san_dict) {
                const node = san_dict[san];
                const opening_tree_node = new OpeningTreeNode(node);
                if (this.depth_map[opening_tree_node.ply] === null || this.depth_map[opening_tree_node.ply] === undefined) {
                    this.depth_map[opening_tree_node.ply] = [];
                }
                this.depth_map[opening_tree_node.ply].push(opening_tree_node);
                result_fen_array.push(opening_tree_node);
            }
            result_fen_array.sort((a, b) => (a.count > b.count) ? -1 : 1);
            this.map[fen_key] = result_fen_array;
        }
    }
    
    nodesFromFEN(fen: string): OpeningTreeNode[] {
        fen = fen.split(" ", 3).join(" ")
        return this.map[fen] ?? [];
    }

    nodesForDepth(treeDepth: number): OpeningTreeNode[] {
        return this.depth_map[treeDepth] ?? [];
    }
}

export class OpeningTreeNode {
    count: number;
    winrate: number;
    drawrate: number;
    move: Move;
    fen: string;
    move_number: number;
    pgn: string;
    ply: number;

    constructor(dictionary: any) {
        this.drawrate = dictionary["drawrate"] as number;
        this.winrate = dictionary["winrate"] as number;
        this.count = dictionary["count"] as number;
        this.move = new Move(dictionary["move"], dictionary["san"] as string);
        this.fen = dictionary["fen"] as string;
        this.move_number = dictionary["move_number"] as number;
        this.pgn = dictionary["pgn"] as string;
        this.ply = dictionary["ply"] as number;
    }

    loserate() {
        return 1 - this.drawrate - this.winrate;
    }

    wins_count(): number {
        return Math.round(this.winrate * this.count)
    }

    loss_count(): number {
        return this.count - this.wins_count() - this.drawcount()
    }

    drawcount(): number {
        return Math.round(this.drawrate * this.count)
    }
}
