import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from 'react-router-dom';
import { useState, useRef } from 'react';
import './Search.css';
import './MultipleProfileDialog/MultipleProfileDialog.tsx'
import AlertDialog from './MultipleProfileDialog/MultipleProfileDialog';
import EmptyInputDialog from './EmptyInputDialog/EmptyInputDialog'
import SearchParamTab from './SearchParamsTab/SearchParamsTab';
import { SearchParamsModel } from './SearchParamsTab/SearchParamsModel';
import SearchTitleComponent from './TypographyComponents/SearchTitleComponent';
import SearchIconsComponent from './TypographyComponents/SearchIconsComponent';
import { Box, Typography, Alert } from '@mui/material';
import ReactGA from 'react-ga4';

export default function Search() {
    const profileRef = useRef<any>()
    const [loading, setLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [emptyInputOpen, setEmptyInputOpen] = useState(false);
    const [searchParamsModel, setSearchParamsModel] = useState(new SearchParamsModel());
    const [serverError, setServerError] = useState(false);

    const keyDownEvent = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.code === "Enter") {
            handleProfile();
        }
    }

    const navigate = useNavigate();

    const createPost = (opening_tree_data: Map<string, Object>) => {
        navigate('/opening_tree',
            {
                state: {
                    data: opening_tree_data,
                }
            });
    }

    const showMultipleProfilePopup = () => {
        setDialogOpen(true);
    }

    const emptyTree = () => {
        navigate('/empty_tree');
    }

    const handleProfile = () => {
        // empty input
        const input = profileRef?.current.value
        if (input.length === 0) {
            setEmptyInputOpen(true);
        } else {
            loadProfile();
        }
    };

    const handleFreeBrowsing = () => {
        ReactGA.event(
            {
                category: 'user-events',
                action: 'empty-tree-lookup'
            }
        )
        setLoading(true);
        emptyTree();
    }

    const loadProfile = (profileFlag?: string) => {
        ReactGA.event(
            {
                category: 'user-events',
                action: 'search-with-profile'
            }
        )

        setLoading(true);
        setServerError(false);
        var url = process.env.REACT_APP_API_URI + "/search/?profile=" + profileRef?.current.value
        if (profileFlag !== null) {
            url = url + "&profile_flag=" + profileFlag
        }

        url = url + "&game_types=" + searchParamsModel.getGameTypes()
        url = url + "&number_of_games=" + searchParamsModel.numberOfGames
        url = url + "&time_range=" + searchParamsModel.timeSince
        url = url + "&only_rated=" + searchParamsModel.onlyRated

        fetch(url, { cache: "default" })
            .then(res => res.json())
            .then(
                (result) => {
                    setLoading(false);
                    const parsed_json = JSON.parse(result);
                    if (parsed_json['both_profiles'] === true) {
                        // need to show popup and send back callback to server
                        showMultipleProfilePopup();
                    } else {
                        createPost(JSON.parse(result));
                    }
                },
                (error) => {
                    setServerError(true);
                    setLoading(false);
                }
            )
    }

    const lichessSelected = () => {
        setDialogOpen(false)
        loadProfile("lichess")
    }

    const chessComSelected = () => {
        setDialogOpen(false)
        loadProfile("chess_com")
    }

    const handleCloseOfEmptyInput = () => {
        setEmptyInputOpen(false);
    }

    const filterModelChanged = (filterModel: SearchParamsModel) => {
        setSearchParamsModel(filterModel)
    }

    let serverErrorAlert = null;
    if (serverError === true) {
        serverErrorAlert = (<Alert sx={{ mt: 2 }} severity="error">Server error. Please try again later</Alert>)
    }

    return (
        <header className="Search-bar" onKeyDown={keyDownEvent}>
            <SearchTitleComponent />
            <SearchIconsComponent />
            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <SearchParamTab modelDidChange={filterModelChanged} />
                {serverErrorAlert}
                <Box component="section"
                    sx={{
                        maxWidth: { sm: 0.8, xs: 0.9, md: 0.7 },
                        mx: 3,
                        borderRadius: 3,
                        flexDirection: 'row',
                        display: 'flex',
                        alignItems: 'center',
                        bgcolor: 'background.paper',
                        marginTop: 2,
                        marginBottom: 2,
                    }}>
                    <TextField
                        margin="normal"
                        id="profile-text-field"
                        label="Enter your Chess.com or Lichess profile"
                        variant="outlined"
                        inputRef={profileRef}
                        sx={{
                            margin: 3, minWidth: {
                                sm: 325,
                                md: 475,
                            }
                        }}
                    />
                    <LoadingButton
                        onClick={handleProfile}
                        loading={loading}
                        variant="contained"
                        sx={{ minWidth: 90, marginRight: 3 }}
                    >
                        <span>Search</span>
                    </LoadingButton>
                </Box>
                <Box component="section"
                    sx={{
                        maxWidth: { sm: 0.8, xs: 0.9, md: 0.7 },
                        borderRadius: 3,
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        bgcolor: 'background.paper',
                        margin: 3,
                    }}>
                    <Typography sx={{ m: 4, marginBottom: 2 }} color={'secondary'} align='center'>
                        Feel free to explore our learning materials, even if you don’t<br/> have a chess account or simply want to browse.
                    </Typography>
                    <LoadingButton
                        onClick={handleFreeBrowsing}
                        loading={loading}
                        variant="contained"
                        sx={{ marginBottom: 4 }}
                    >
                        <span>Dive in</span>
                    </LoadingButton>
                </Box>
                <AlertDialog
                    open={dialogOpen}
                    lichessSelected={lichessSelected}
                    chessComSelected={chessComSelected}
                />
                <EmptyInputDialog
                    open={emptyInputOpen}
                    onClose={handleCloseOfEmptyInput}
                />
            </Box>
        </header>
    );
}