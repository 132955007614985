import { useState, useEffect, useCallback } from 'react';
import { Chess } from "chess.js";
import './EmptyTree.css';
import Grid from '@mui/material/Grid';
import RightPane from '../components/right_pane/RightPane';
import { Square } from 'react-chessboard/dist/chessboard/types';
import OpeningTreeHeader from '../components/middle_pane/components/header/OpeningTreeHeader';
import { Box } from '@mui/material';
import LogoComponent from '../../common/logo_card/LogoComponent';
import ChessboardControlsComponent from '../components/chessboard/ChessboardControlsComponent';
import ChessboardComponent from '../components/chessboard/ChessboardComponent';
import AdsenseComponent from '../components/ads/AdsenseComponent';
import { useLocation } from 'react-router-dom';
import { getQueryVariable } from '../../common/helpers'

function EmptyTree() {
    const [game, setGame] = useState(new Chess());
    const [largestPGN, setLargestPGN] = useState('');
    const [fen, setFen] = useState('');
    const [openingString, setOpeningString] = useState('');
    const [currentColor, setCurrentColor] = useState(true);
    const { search } = useLocation();

    const updateChessboard = useCallback(() => {
        // set largest pgn for forward arrowkey navigation
        if (game.pgn().length > largestPGN.length) {
            setLargestPGN(game.pgn())
        } else {
            if (largestPGN.startsWith(game.pgn()) === false) {
                setLargestPGN(game.pgn())
            }
        }

        if (game.pgn().length === 0) {
            setOpeningString('')
        } else {
            const fen = game.fen().split(" ", 3).join(" ")
            const new_opening_name = localStorage.getItem(fen);
            if (new_opening_name !== null) {
                setOpeningString(new_opening_name);
            }
        }

        setFen(game.fen());

        // preserve current state
        window.sessionStorage.setItem("game", game.pgn());
    }, [largestPGN, game]);

    useEffect(() => {
        const fen_search_param = getQueryVariable("fen", search);
        if (fen_search_param !== null) {
            const default_append = ["w", "-", "-", "0", "1"];
            var fen_array = fen_search_param.split(' ');
            const insertions = 6 - fen_array.length;

            var append_array = default_append.slice(-insertions);
            game.load(fen_array.concat(append_array).join(' '));
            setFen(fen_search_param);
            setOpeningString('');
            updateChessboard();
        }
    }, [search, game, updateChessboard]);

    // when game object changes, we need to update most of the things to reflect that
    useEffect(() => {
        const pgn = window.sessionStorage.getItem("game");
        if (pgn !== null) {
            game.loadPgn(pgn);
        }
        updateChessboard();
    }, [game, updateChessboard]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const keyDownEvent = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.code === "ArrowRight") {
            forwardVariation();
        }
        if (event.code === "ArrowLeft") {
            retraceVariation();
        }
    };

    const forwardVariation = () => {
        const tempGame = new Chess();
        tempGame.loadPgn(game.pgn());
        const sl = tempGame.history().length
        tempGame.loadPgn(largestPGN);
        var ll = tempGame.history().length
        while (ll !== sl + 1 && ll > sl) {
            tempGame.undo();
            ll -= 1;
        }
        game.loadPgn(tempGame.pgn());
        updateChessboard();
    }

    const retraceVariation = () => {
        game.undo();
        updateChessboard();
    }

    const onPieceDrop = (sourceSquare: Square, targetSquare: Square) => {
        const move = game.move(
            {
                from: sourceSquare,
                to: targetSquare,
                promotion: "q"
            }
        )
        if (move === null) {
            return false;
        }
        updateChessboard();
        return true;
    }

    const movesDidChange = (pgn: string) => {
        game.loadPgn(pgn);
        updateChessboard();
    }

    const resetBoard = () => {
        game.loadPgn('');
        updateChessboard();
    }

    const flipBoard = () => {
        setCurrentColor(!currentColor);
        const new_game = new Chess();
        setGame(new_game);
    }

    const analyseOnChessCom = () => {
        const url = "https://www.chess.com/analysis?tab=analysis&pgn=" + game.pgn().split(' ').join('+') + '&move=' + game.moves().length;
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    const analyseOnLichessOrg = () => {
        const url = "https://lichess.org/analysis/pgn/" + game.pgn().split(' ').join('_')
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    return (
        <header className="Container-opening-tree" onKeyDown={keyDownEvent} tabIndex={0}>
            <Box mt={2} style={{ userSelect: "element" }}>
                <LogoComponent />
            </Box>
            <Grid container spacing={3}>
                {/* Chess board renderer. TODO: move to a separate component */}
                <Grid item xs={12} md={6} lg={5}>
                    <Box sx={{
                        m: 2,
                        borderRadius: 3
                    }}
                        overflow={'clip'}>
                        <ChessboardComponent
                            fen={fen}
                            onDrop={onPieceDrop}
                            color={currentColor}
                        />
                    </Box>
                    <ChessboardControlsComponent
                        previousMove={retraceVariation}
                        nextMove={forwardVariation}
                        resetBoard={resetBoard}
                        setPgn={movesDidChange}
                        flipBoard={flipBoard}
                        analyseOnChessCom={analyseOnChessCom}
                        analyseOnLichessOrg={analyseOnLichessOrg} />
                </Grid>
                <Grid item xs={12} md={6} lg={7}>
                    <Box m={2}>
                        <OpeningTreeHeader
                            movesCount={0}
                            currentTreeDepth={0}
                            currentMoves={game.pgn()}
                            movesDidChange={movesDidChange}
                            currentOpening={openingString}
                        />
                    </Box>
                    <RightPane
                        fen={fen}
                    />
                </Grid>
            </Grid>
            <Box width={'100%'}>
                <center>
                    <AdsenseComponent adKey='-6t+dg+58-2b-89' adFormat='fluid' adSlot='8883624237' />
                </center>
            </Box>
        </header>
    );
}

export default EmptyTree;
