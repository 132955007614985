import { Box, Typography, Grid } from '@mui/material';
import footerImage from '../../dancing_chess.png';
import IconCardComponent from '../../common/icon_card/IconCardComponent';
import ReactGA from 'react-ga4'
import { useEffect } from 'react';

export default function Support() {
    useEffect(() => {
        ReactGA.event(
            {
                category: 'user-events',
                action: 'main-page-support'
            }
        )
    }, []);
    return (
        <Box
            component="section"
            sx={{
                p: {
                    xs: 3,
                    sm: 8
                },
                paddingBottom: {
                    xs: 2,
                    sm: 2
                },
                overflow: 'clip'
            }}>
            <Typography
                variant='h2'
                style={{ fontWeight: 700 }}
                letterSpacing={1.5}
                align='left'
                sx={{ paddingBottom: 2 }}>
                Become part of our team and <br /> support development of this website
            </Typography>
            <Typography
                variant='body1'
                color={'text.secondary'}
                align='left'>
                Our project is fueled by passion, and you can play a crucial role in our dream of building the world’s largest chess learning database. <br />
                This website is crafted by a small team of chess enthusiasts, not a corporate giant. <br />
                Every dollar contributes to keeping this site running, and we appreciate your support.
            </Typography>
            <Grid container spacing={4} mt={1}>
                <Grid item xs={12} md={6}>
                    <IconCardComponent
                        iconType='Credit_Card'
                        title='Patreon membership'
                        text='By becoming a regular supporter through Patreon membership, you’ll help us maintain the website and develop new features for your benefit.'
                        href='http://patreon.com/bookmoves' />
                </Grid>
                <Grid item xs={12} md={6}>
                    <IconCardComponent
                        iconType='Ad_Block'
                        title='Disable your adblock'
                        text='Please consider disabling your ad blocker for this website. We have minimal amount of ads, thoughtfully designed not to disrupt your experience while using the resource.' />
                </Grid>
            </Grid>
            <Box
                component="img"
                alt=''
                src={footerImage}
                sx={{
                    width: { md: 1920, xs: 960 },
                    position: 'relative',
                    left: 0,
                    ml: -8,
                }} />
        </Box>
    );
}