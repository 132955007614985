import { Chessboard } from 'react-chessboard';
import { memo } from 'react';
import { Square } from 'chess.js';

type ChessboardProps = {
    fen: string;
    color: boolean;
    onDrop: (sourceSquare: Square, targetSquare: Square) => boolean;
  }  

const ChessboardComponent = memo(function ChessboardComponent(props: ChessboardProps) {
    return (
        <Chessboard
            id="BasicBoard"
            position={props.fen}
            onPieceDrop={props.onDrop}
            boardOrientation={props.color ? 'white' : 'black'}
        />
    );
});

export default ChessboardComponent;