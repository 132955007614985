import './App.css';
import { Route, Routes } from "react-router-dom"
import OpeningTree from './opening_tree/OpeningTree';
import Home from './home/Home';
import EmptyTree from './opening_tree/empty_tree/EmptyTree';
import { ThemeProvider, createTheme, ThemeOptions, responsiveFontSizes } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import type {} from '@mui/lab/themeAugmentation';
import ReactGA from 'react-ga4';
import Privacy from './privacy/Privacy';
import Blog from './home/Blog/Blog';
import BlogDetails from './home/Blog/Details/BlogDetails';

import opening_book from './db_ECO_opening.json'

export const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: '#7f5af0',
    },
    secondary: {
      main: '#72757e',
    },
    background: {
      default: '#242629',
      paper: '#16161a',
    },
    text: {
      primary: '#fffffe',
      secondary: '#94a1b2',
    },
    warning: {
      main: '#2cb67d',
    }
  },
  typography: {
    fontFamily: 'Nunito',
    fontSize: 16,
  },
  components: {
    MuiLoadingButton: {
      styleOverrides: {
        loadingIndicator: {
          color: '#2cb67d'
        }
      }
    }
  }
};

let theme = createTheme(themeOptions);
theme = responsiveFontSizes(theme);

function createOpeningDict() {
  if (localStorage.key(0) !== null) {
    return;
  }
  for (const key in opening_book) {
    let value = opening_book[key];
    let opening_name = value["opening_name"] as string;
    if (value["variation"] !== null) {
      opening_name = opening_name + ' - ' + value["variation"];
    }
    const fen = value["fen"] as string;
    localStorage.setItem(fen, opening_name);
  }
} 

function App() {
  ReactGA.initialize('G-QJV8F4C06Y');
  createOpeningDict();
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/opening_tree" element={<OpeningTree />} />
        <Route path="/empty_tree" element={<EmptyTree />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog_post" element={<BlogDetails />}/>
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;