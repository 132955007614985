import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

type ContributionValidationDialogProps = {
    open: boolean;
    closeCallback: () => void;
}

export default function ContributionValidationDialog(props: ContributionValidationDialogProps) {
  const handleClose = () => {
    props.closeCallback();
  };

  return (
      <Dialog
        open={props.open}
        onClose={handleClose}
      >
        <DialogTitle>
          {"Invalid submission"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please provide contribution type and URL to the resource. Thank you. 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
  );
}